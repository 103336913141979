.middle-footer {
  padding: 150px 0 50px;
  background: url("../../images/footer-bkg.jpg") no-repeat top right $light-blue;

  @include media('<tablet') {      
    padding: 0px 0 34px;
  }

  .row.top-footer {
    align-items: center;
    justify-content: center;    
    background-color: $blue;
    padding-left: 45px;
    padding-right: 45px;

    @include media('<tablet') {      
      padding: 40px 0px;
    }

    @include media('>=tablet') {      
      margin: -250px 0 0;
    }   

    @include media('>=tablet','<widescreen') {      
      padding: 40px 25px;
    }

    @include media('>=widescreen') {      
      height: 200px;
    }

    .call-to-action {
      @include media('<=widescreen') {
        margin-top: 30px;
      }

      p, .phone, span {
        color: $white;
      }

      p {
        font-size: 17px;
        line-height: 1;
      }

      .phone {
        font-size: 65px;
        margin-bottom: 0;

        @include media('<tablet') {      
          font-size: 48px;
          margin: 18px 0 15px;
        }
      }

      .btn {
        display: none;
      }

      span {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }

  .map-form-container {
    padding-top: 50px;

    @include media('<tablet') {
      padding-top: 12px;
    }

    .map-details-wrap {

      ul {

        li {

          a {
            border-color: $white;
            color: $white;

            &:hover {
              border-color: $orange;
            }

          }
        }
      }
    }
  }
}

.map-details-wrap {
  @include media('>=tablet','<desktop') {
    margin-bottom: 40px;
  }

  @include media('>=desktop','<widescreen') {
    max-width: 600px;
    margin: 0 auto 40px;
  }

  #map-canvas,
  #map-canvas1 {
    background-repeat: no-repeat;
    background-position: center top; 
    width: 592px;
    height: 339px;
    border-width: 0 10px 10px;
    border-color: $white;
    border-style: solid;

    @include media('<tablet') {
      max-width: 100%;
      height: 201px;
      margin-top: 30px;
      margin-bottom: 25px;
      background-position: center center; 
    }
  }

  .law-firm-footer-title {
    margin-bottom: 19px;        
    color: $white;
    font-family: $font-work;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    display: block;
    margin-top: 30px;
  }

  .wpseo-address-wrapper {        
    color: $white;
    font-family: $default-font;
    font-size: 18px;
    line-height: 30px;
  }

  .phone-address {        
    color: $white;
    font-family: $default-font;
    font-size: 18px;
    line-height: 18px;
    margin-top: 15px;

    a {
      color: $white;
    }
  }

  ul {
    margin-top: 56px;
    display: flex;
    flex-direction: row;

    @include media('<tablet') {
      margin-top: 24px;
    }

    li {
      &:first-of-type {
        margin-right: 30px;
      }

      a {
        max-width: 160px;
        width: 160px;
        padding: 12px 15px !important;

        @include media('<tablet') {
          width: 155px;
        }
      }
    }
  }
}

.social-icon-list {
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include media('<widescreen') {
      justify-content: center;
      margin-top: 30px;
    }

    li {
         margin-right: 30px;

         @include media('>=widescreen','<1400px') {
           margin-right: 14px;
         }

         &:last-of-type {
          margin-right: 0;
         }
    }

    a {
        text-align: center;
        display: block;
        height: 50px;
        width: 50px;
        background-color: $yellow;
        padding-top: 11px;

        &:hover {
            background-color: $light-blue;
        }
    }
}

.bottom-footer {
  background-color: $lavarich;
  padding: 53px 0 45px;
  position: relative;

  @include media('<tablet') {
   padding-top: 30px;
   padding-bottom: 80px;
  }

  @include media('>=tablet','<desktop') {
    padding-bottom: 120px;
  }

  p {    
    color: #666666;
    font-family: $default-font;
    font-size: 14px;
    line-height: 15px;

    @include media('<tablet') {
     line-height: 20px;
    }

    img {
      margin-right: 15px;

      @include media('<tablet') {
        margin-right: 8px;
        margin-top: 3px;
      }
    }

    &:first-of-type {
      margin-bottom: 28px;
      margin-top: 40px;

      @include media('<tablet') {
       margin-top: 29px;
      }
    }

    a {
      color: #666666;
    }
  }

  .footer-menu-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @include media('<widescreen') {
      flex-direction: column;
      align-items: center;
    }

    span {
      color: $light-blue;
      font-family: $font-work;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 3px;
      line-height: 21px;
      text-transform: uppercase;
      margin-right: 30px;

      @include media('<desktop') {
        margin-right: 0;
        margin-bottom: 18px;
      }
    }

    #menu-footer-menu {
      @include media('>=desktop') {
        flex-direction: row;
      }

      @include media('>=desktop','<widescreen') {
        margin-top: 20px;
      }

      li {
        @include media('<desktop') {
          margin-bottom: 14px;
          text-align: center;
        }

        @include media('>=desktop') {
          padding-right: 30px;
          border-right: 2px solid #b1bcda;
          margin-right: 30px;
          margin-bottom: 0;

          &:last-of-type {
            border-right: none;
          }
        }

        @include media('>=desktop','<widescreen') {
          margin-right: 25px;
        }

        a {          
          color: $light-blue;
          font-family: $default-font;
          font-size: 16px;
          padding:0; 
        }
      }
    }
  }
}

#return-to-top {
    position: absolute;
    z-index: 5;
    bottom: 40px;
    right: 40px;
    background-image: url("../../images/icons/back-to-top.svg");
    width: 60px;
    height: 59px;
    box-shadow: 0px 12px 17px 0px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    text-decoration: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; 
    text-indent: -9999px;
    color: transparent;

    @include media('<tablet') {
      display: none;
      position: fixed;
      bottom: 10px;
      right: 10px;
    }

    @include media('>=tablet','<desktop') {
      bottom: 25px;
    }
}