#blog {
  background-image: none;

  @include media('<tablet') {
    padding-bottom: 0;
  }

  h1 {
    margin-bottom: 40px;
  }

  .post-excerpt {
    margin-bottom: 50px;

    .blog-post {
      background: $light-grey;
      padding: 43px 56px;

      @include media('<tablet') {
        padding: 30px 15px;
      }

      .excerpt-title {
        padding-bottom: 25px;

        .title-wrap {

          h2.post-title {
            text-align: left;
            margin-bottom: 0;

            a {
              color: $blue;
              text-decoration: none;

              &:hover {
                color: $yellow;
              }

            }

          }

        }

        .post-meta {
          color: #6c6c6c;
          font-family: $default-font;
          font-size: 15px;
          font-weight: 400;
          line-height: 33px;
          margin-top: 20px;

          a {
            color: $blue;
            text-decoration: underline;
          }          
        }

        .post-category {
          display: inline-block;

          &:last-of-type {
            margin-right: 0;

            .comma {
              display: none;
            }

          }

        }

      }

      .post-wrap {

        .blog-excerpt {

          .image-holder {
            //margin-bottom: 30px;
            margin-right: 30px;
            float: left;
            max-width: 275px;
            padding-top: 7px;

            @include media('>=tablet') {              
              width: 275px;
            }

            @include media('<widescreen') {
              margin: 0 auto 20px;
              float: none;
            }

            @include media('>=widescreen') {
              margin-bottom: 30px;
            }

            img {
              box-shadow: 4px 4px 7px 1px rgba(0, 0, 0, 0.07);
              max-height: 350px;
              width: 100%;
            }

          }

          .excerpt-content {
            margin-bottom: 30px;            
            color: $text;
            font-family: $default-font;
            font-size: 18px;
            font-weight: 400;
            line-height: 33px;

            a {
              display: none;
            }

          }

          .btn-read-more-wrap {
            clear: both;

            .btn--2 {
              width: 100%;
              max-width: 268px;
              text-decoration: none;
              margin-bottom: 0;

              @include media('<desktop') {
                margin-left: auto;
                margin-right: auto;
                display: block;
              }

              @include media('>=desktop') {                
                float: right;
              }
            }

            @extend %clearfix;
          }

        }

      }

    }

  }

}