$white: #fff;
$black: #000;

$text: #032e43;
$heading-text: #2C2C2C;

$blue: #092249;
$light-blue: #1a3173;
$yellow: #fb9127;
$lavarich: #d5ddf2;

$grey: #565656;
$border-grey: #cfcfcf;
$light-grey: #ececf2;

$lighter-grey: #b7b7b7;
$dark-grey: #707070;
$darker-grey: #3e3e3e;
$alt-grey: #ebeeea;

$default-font: 'Open Sans', sans-serif;
$font-work: 'Work Sans', sans-serif;

$bg-location: #ececf2;
$alt-bg: #ececf2;