.awards-alt {
  padding-top: 0px;
      
  @include media('<tablet') {
    padding-bottom: 30px;
  }
}

.awards-part {
  .awards-title {    
    color: #666666;
    font-family: $font-work;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 23px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }

  a:first-of-type {
    margin-right: 39px;

    @include media('<tablet') {
      margin-right: 20px;
    }

    @include media('>=widescreen','<1400px') {
      margin-right: 31px;
    }

    @include media('>=desktop','<widescreen') {
      margin-right: 7px;
    }
  }

  img{
    @include media('>=desktop','<widescreen') {
      max-width: 128px;
    }

    @include media('<tablet') {
      max-width: 142px;
    }

    @include media('>=widescreen','<1400px') {
      max-width: 144px;
    }
  }
}