.sidebar {
  @include media('<tablet') {
    padding-right: 10px !important;
  }

  @include media('<desktop') {
    margin-top: 50px;
  }

  .sidebar-widgets {
    padding-left: 0;
  }

  &-title {
    color: #666666;
    font-family: $font-work;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 23px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 30px;
  }

  //Results

  &-results {
    background-color: $lavarich;
    padding-bottom: 40px;
    position: relative;

    .small-section-title {
      background-color: #c2cce5;
      color: #1e3576;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 3px;
      line-height: 32px;
      text-transform: uppercase;
      font-family: $font-work;
      margin-bottom: 30px;
    }

    .btn {
      width: 100%;
      max-width: 320px;
      margin-top: 15px;
    }

    .custom-nav-testimonials .slick-arrow, .custom-nav-results .slick-arrow {
      top: 42%;
    }
  }

  //Info List
  &-info {
    border: 2px solid #d3d3d6;

    .sidebar-title {
      background-image: url('../../images/related-info-pattern.png');
      height: 79px;
      padding-top: 28px;
    }
  }

  &-list {
    padding: 0 45px 30px;

    li {
      color: $text;
      font-family: $default-font;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-top: 2px solid #d3d3d6;
      text-align: left;
      position: relative;
      padding: 15px 0px;

      &:last-of-type {
        border-bottom: 2px solid #d3d3d6;
      }

      ul.children {
        padding-left: 20px;
        
        li {
          border-top: none;

          &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }

      a {
        color: $text;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  //CTA
  .call-to-action {
    p {
      font-size: 14px;
    }

    .phone {
      font-size: 50px;
    }
  }
}


.siderbar-item {
  margin-bottom: 50px;
  list-style: none;

  &:empty {
    display: none;
  }

  // Form
  &.sidebar-form {
    background-color: $light-blue;
    padding: 40px 22px 30px;

    .form-subtitle {
      font-size: 18px;
      letter-spacing: 2px;
      line-height: 21px;
      padding: 0 10px;
      margin-bottom: 13px;

      br {
        display: block;
      }

      @media screen and (min-width: 768px) {
        padding: 0 25px;
      }

      @media screen and (min-width: 1400px) {
        padding: 0 63px;
      }

      .single-attorney & {
        @media screen and (min-width: 1200px) and (max-width: 1269px) {
          padding: 0;
        }
      }
    }

    .form-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 35px;

      @media screen and (min-width: 1400px) {
        font-size: 30px;
      }
    }

    .btn {
      padding: 20px 5px;

      @media screen and (min-width: 768px) {
        padding: 20px 30px;
      }
    }
  }

  &.sidebar-awards {
    .awards-part {
      justify-content: center;
      display: flex;
    }
  }

  //Testimonials Sidebar

  .testimonial-part {
    .btn {
      width: 100%;
      max-width: 320px;
    }
  }
}

// Team sidebar
.sidebar-team {
  position: relative;
  background-color: $light-grey !important;
  justify-content: center !important;
  padding: 40px 55px !important;

  @include media('<tablet') {
    padding: 40px 20px !important;
  }

  .btn {
    margin-top: 20px;
    width: 100%;

    @include media('>=desktop', '<widescreen') {
      padding-left: 0;
      padding-right: 0;
    }

  }

  .attorney-item a {
    margin-left: auto;
    margin-right: auto;
  }
}

// Navs
.sidebar-navs {
  .slick-prev {
    left: 25px !important;
  }

  .slick-next {
    right: 25px !important;
  }
}

.sidebar-search {
  background-color: $lavarich;
  padding: 36px 20px;

  .input-std {
    color: #5f5f5f;
    font-family: $default-font;
    font-size: 18px;
    font-weight: 400;
    width: 100%;
    padding: 23px 70px 23px 15px;
  }

  #searchsubmit {
    position: absolute;
    text-indent: -9999px;
    color: transparent;
    right: 0;
    top: 0;
    height: 36px;
    width: 49px;
    background: url("../../images/shape.png") center center no-repeat $blue;
  }

  .search-form {
    position: relative;
  }
}

.toc-container {
  box-shadow: 6px 6px 26px rgba(0, 0, 0, 0.2);

  @include media('<desktop') {
    margin-bottom: 35px;
  }

  .toc-title {
    background-color: $light-blue;
    padding: 38px;
    color: $white;
    font-family: $font-work;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 3px;
    line-height: 23px;
    text-transform: uppercase;
    margin-bottom: 30px;

    a {
      position: relative;
      display: block;
      cursor: pointer;
      color: $white;

      i {
        position: absolute;
        right: 0;
        top: 0px;
        color: $yellow;
        font-size: 30px;
      }

      &:hover {
        color: $white;
      }
    }

  }

  /*ul {
      background-color: $white;
      margin-top: 25px;

      @include media('<desktop') {
          padding-left: 0 !important;

          li {
              margin-bottom: 0 !important;
              padding-left: 0 !important;

              &:after {
                  display: none !important;
              }
          }
      }
  }*/
}