.share-wrap {
  margin-top: 25px;
  margin-bottom: 50px;
  padding-top: 30px;
  border-top: 1px solid $border-color;

  .social-icon-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      color: #333333;
      display: inline-block;
      font-weight: 600;
    }

    a {
      color: $white;
      //margin-left: 15px;

      &:hover {
        color: $blue;
      }

    }

    svg {
      fill: $white !important;
    }

    li{
      @include media('<tablet') {
        margin-right: 6px;
      }
      
      &:nth-child(2) {
        svg {
          //width: 40px;
          //height: 40px;
          //margin-top: 6px;

          path {
            fill: $white !important;
          }
        }
      }

      &:nth-child(5) {
        svg {
          /*width: 40px;
          height: 40px;
          margin-top: 6px;*/

          path {
            fill: $white !important;
          }
        }
      }
    }
  }

}