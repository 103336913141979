.breadcrumb-container {

  .page-template-attorney-template & {
    background-color: transparent;

    @include media('>=tablet','<desktop') {
      margin-top: -85px;
    }
  }

  .breadcrumb {
    font-size: 14px;
    line-height: 22px;
    padding: 14px 15px 0; //custom
    list-style: none;
    border-radius: 0;
    color: $white;
    letter-spacing: 0;
    margin: 0;
    background-color: transparent;

    a {
      color: $yellow;
      text-decoration: none;

      &:after {
        content: '';
        positon: relative;
        margin: 0 10px 0 12px;
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 7px;
        border-color: transparent transparent transparent #ffffff;
      }

      &:hover {
        color: $white;
      }

    }

  }

}

.breadcrumb-alt {

  .breadcrumb {
    color: $blue !important;
    margin-top: 30px;
  }

  a{
    &:after {
      border-color: transparent transparent transparent $blue !important;
    }

    &:hover {
      color: $blue !important;
    }
  }
}