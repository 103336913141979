.page-sitemap {

  .site-map-content {

    ul li a {
      color: $text;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}