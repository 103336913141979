.hero {

  &-home {
    height: 720px;
    padding-top: 253px;
    background-position: top center;
    position: relative;
    z-index: 0;

    @include media('<tablet') {
      height: 540px;
      padding-top: 184px;
      background-size: cover;
    }

    img {
      margin-bottom: 20px;

      @include media('<tablet') {
        margin-bottom: 17px;
        max-width: 237px;
      }

    }

    .btn {
      margin-top: 45px;

      @include media('<tablet') {
        margin-top: 35px;
        max-width: 337px;
        width: 100%;

        &:first-of-type {
          margin-bottom: 15px;
          margin-left: auto;
          margin-right: auto;
        }

        &:last-of-type {
          margin: 0 auto;
        }

      }

    }

    .tap-call {
      background-color: $white;
      color: $light-blue;
    }

  }

  &-default {
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #092249;
    background-image: url('../../images/banners/about-sansone-banner.jpg');
    min-height: 320px;
    position: relative;

    .page-template-practice-area & {

      @include media('>=tablet', '<desktop') {
        height: auto;
        padding-bottom: 25px;

        .btn.btn--1 {
          margin-top: 20px;
        }

      }

      @include media('<tablet') {
        height: auto;
        padding-top: 25px;
        padding-bottom: 25px;

        .btn.btn--1 {
          margin-top: 20px;
        }

      }

    }

    @media screen and (min-width: 768px) and (max-width: 991px) {

      .container {

        .row.align-items-center {
          height: 245px;

          .page-template-practice-area & {
            height: auto;
          }

        }

      }

    }

    &__interior {

      .banner-title {
        @include media('>=tablet','<desktop') {
          font-size: 37px;
        }
      }

      .banner-content {
        @include media('<desktop') {
          margin-bottom: 30px;
        }
      }

      .btn {

        @include media('>=desktop', '<widescreen') {
          padding-left: 17px;
          padding-right: 17px;
          font-size: 15px;
        }

        @include media('>=widescreen') {
          padding-left: 30px;
          padding-right: 30px;
        }

      }

    }

    @media screen and (max-width: 767px) {
      height: 320px;
      text-align: center;

      .page-template-practice-area & {
        height: auto;
      }

      .container {

        .row.align-items-center {
          height: 320px;
          flex-direction: column;
          justify-content: center;

          .page-template-practice-area & {
            height: auto;
          }

        }

      }

    }

    &__interior {

      .row.align-items-center {
        min-height: 258px;
      }

    }

  }

  &-image {
    height: 283px;

    img {
      position: absolute;
      bottom: 0;
      right: 0;
    }

  }

  &-bio {
    height: 600px;
    background-position: top right;
    position: relative;
    z-index: 0;

    @include media('<tablet') {
      background-position: left 32% center !important;
      height: 420px;
    }

    @include media('>=600px', '<tablet') {
      background-position: left 53% center !important;
    }

    .banner-title {
      padding-top: 96px;
    }

    .position {
      color: $yellow;
      font-family: $font-work;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 3.33px;
      line-height: 23px;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .phone {
      color: $white;
      font-family: $default-font;
      font-size: 24px;
      font-weight: 700;
      line-height: 54px;
      background-image: url("../../images/phone.png");
      background-repeat: no-repeat;
      padding-left: 70px;
      margin: 40px 0 50px;

      a {
        color: $white;
      }
    }
  }

}

.banner-title {
  color: $white;
  font-family: $font-work;
  font-size: 50px;
  font-weight: 600;
  line-height: 58px;
  text-transform: uppercase;

  @include media('>=widescreen', '<1400px') {
    font-size: 42px;
  }

  @include media('<tablet') {
    font-size: 30px;
    line-height: 35px;
  }

  @include media('<375px') {
    font-size: 26px;
    line-height: 31px;
  }

  .hero-default & {
    margin-bottom: 22px;
  }
}

.banner-content {
  color: $white;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

// Attorney Banner
/*.attorney-banner {
  .section-title {
    color: $white;
  }

  .banner-content {
    padding-left: 15px;

    @include media('<tablet') {
      padding-top: 50px;
    }
    
    @include media('>=desktop') {
      padding-top: 96px;
    }
  }

  .banner-subtitle {
    color: $cerulean;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 4px;
    text-transform: uppercase;
  }

  ul {
      margin:25px 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.25);

      li {                
          color: $white;
          font-family: $default-font;
          font-size: 18px;
          font-weight: 600;
          line-height: 30px;
          padding-top: 14px;
          margin-bottom: 14px;
          border-top: 1px solid rgba(255, 255, 255, 0.25);
          padding-left: 35px;

          a {
              color: $white;
          }

          &.phone {
            background: url("../../images/phone.png") no-repeat left top 20px;
          }

          &.email {
              background: url("../../images/email.png") no-repeat left top 20px;
          }
      }
  }

}

// Interior Banner
.hero-interior {
  padding-top: 135px;
  height: 218px;
  background-size: cover;
  background-position: top left;
  position: relative;
  z-index: 0;

  .page-id-1298 & {
    @include media('<tablet') {
      padding-top: 55px;
      height: auto;
    }

    @include media('>=tablet','<desktop') {
      height: 255px;
    }
  }

  @include media('<tablet') {
    padding-top: 33px;
    background-position: top center;
    
    .row {
      height: 218px;
      align-items: center;
      justify-content: center;
    }
  }

  .banner-title {    
    color: $white;
    font-family: $font-merriweather;
    font-size: 38px;
    font-weight: 400;
    line-height: 46px;
  }
}*/