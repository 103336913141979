#attorney-one {
  @include media('>=tablet') {
    padding-bottom: 10px;
  }

  @include media('<tablet') {
    padding-bottom: 50px;
  }
}

#attorney-two {
  @include media('>=tablet') {
    padding: 90px 0 43px;
  }

  @include media('<tablet') {
    padding: 50px 0;
  }

  .col-xl-6:first-of-type {
    @include media('>=widescreen') {
      padding-right: 80px;
    }

    @include media('>=widescreen', '<1344px') {
      padding-right: 106px;
    }
  }

  .img-border {
    margin-top: 0;

    @include media('>=1528px') {
      margin-left: 0;
    }
  }
}

#attorney-three {
  padding-top: 0;
  padding-bottom: 160px;

  @include media('<tablet') {
    padding-bottom: 50px;
  }

  .section-subtitle {
    margin-bottom: 40px;
  }

  .panel-group + h2 {
    margin-top: 80px;
  }

  .panel {
    border-bottom: 2px solid #d3d3d6;

    .ac-title-wrapper {
      border-top: 2px solid #d3d3d6;
      padding-top: 15px;
      color: $blue;
      font-family: $font-work;
      font-size: 30px;
      font-weight: 500;
      line-height: 38px;
      position: relative;

      @include media('>=widescreen', '<1390px') {
        font-size: 24px;
        line-height: 32px;
      }

      @include media('<desktop') {
        font-size: 23px;
        line-height: 31px;
      }

      a{
        width: 100%;
        display: block;

        @include media('<tablet') {
          padding-top: 10px;
          padding-bottom: 10px;
          min-height: 86px;
        }

        @include media('>=desktop','<widescreen') {
          padding-top: 10px;
          padding-bottom: 10px;
          min-height: 86px;
        }

        &.collapsed:after {
          background-image: url("../../images/plus.png");
        }

        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 30px;
          height: 31px;
          background-image: url("../../images/close.png");
        }
      }

      img {
        margin-right: 0px;

        @include media('<tablet') {
          position: absolute;
          left: -15px;
          top: 0;
        }

        @include media('>=desktop','<widescreen') {
          position: absolute;
          left: -15px;
          top: 0;
        }
      }

      span {
        display: inline-block;
        margin-top: 24px;

        @include media('<tablet') {
          padding-left: 85px;          
          padding-right: 30px;
          margin-top: 0;
        }

        @include media('>=desktop','<widescreen') {
          padding-left: 85px;          
          padding-right: 30px;
          margin-top: 0;
        }
      }
    }

    ul {
      margin-top: 0;

      @include media('>=widescreen') {        
        padding-left: 124px;
        margin-top: -16px;
      }
    }
  }
}