#infographic-accidents-due-to-snow {
margin: 30px auto;
max-width: 960px;
}
@media screen and (max-width: 989px) { #infographic-accidents-due-to-snow { margin: 0 auto; } }
#infographic-header {
background-repeat: no-repeat; background-size: 100%;
padding: 10px 30px;
}
@media screen and (max-width: 479px) { #infographic-header { background-position: top; background-size: 150%; } }
@media screen and (min-width: 480px) and (max-width: 767px) { #infographic-header { background-position: top; background-size: 100%; } }
@media screen and (max-width: 767px) {
#infographic-header {
  padding: 10px 15px;
}
}
#infographic-header-title {
color: white;
font-family: 'Source Sans Pro', sans-serif; font-size: 56px; font-weight: 900;
line-height: 51px;
margin-bottom: 350px;
text-align: center; text-shadow: 2px 2px #153c54; text-transform: uppercase;
}
@media screen and (max-width: 749px) { #infographic-header-title { margin-bottom: 0; } }
@media screen and (min-width: 480px) and (max-width: 639px) { #infographic-header-title { margin: 0 auto 30px; } }
@media screen and (min-width: 640px) and (max-width: 767px) { #infographic-header-title { margin: 0 auto 100px; } }
@media screen and (max-width: 767px) {
#infographic-header-title {
  font-size: 40px;
  line-height: 40px;
  max-width: 525px;
}
}
@media screen and (min-width: 768px) and (max-width: 864px) { #infographic-header-title { margin-bottom: 150px; } }
.infographic-header-item {
align-items: center;
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
margin: 0 auto 30px;
max-width: 90%
}
@media screen and (max-width: 767px) {
.infographic-header-item {
  flex-direction: column;
  max-width: 100%;
}
}
.infographic-header-item .infographic-item-icon { max-width: calc(20% - 15px); }
@media screen and (max-width: 767px) {
.infographic-header-item .infographic-item-icon {
  margin: 0 auto 15px;
  max-width: 100px;
}
}
.infographic-header-item .infographic-item-copy {
color: #076a8c;
font-family: 'Open Sans', sans-serif; font-size: 16px; font-weight: 400;
max-width: calc(80% - 15px);
}
@media screen and (max-width: 767px) {
.infographic-header-item .infographic-item-copy {
  max-width: 100%;
  text-align: center;
}
}
.infographic-header-item .infographic-item-copy a {
color: #076a8c;
font-weight: bold;
text-decoration: underline;
}
#infographic-statistics-panel {
background-color: #266b96; background-position: top right; background-repeat: no-repeat; background-size: 100%;
padding: 50px;
}
@media screen and (max-width: 767px) { #infographic-statistics-panel { padding: 15px; } }
#infographic-statistics-title {
color: white;
font-family: 'Source Sans Pro', sans-serif; font-size: 40px; font-weight: 900;
line-height: 40px;
margin-bottom: 30px;
max-width: 71%;
text-align: center; text-shadow: 2px 2px #153c54; text-transform: uppercase;
}
@media screen and (max-width: 767px) { #infographic-statistics-title { max-width: 100%; } }
#infographic-statistics-items {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
max-width: 80%;
}
@media screen and (max-width: 767px) { #infographic-statistics-items { max-width: 100%; } }
.infographic-statistics-item {
margin-bottom: 30px;
max-width: calc(50% - 15px);
}
@media screen and (max-width: 767px) {
.infographic-statistics-item {
  margin: 0 auto 30px;
  max-width: 100%;
  text-align: center;
}
}
.infographic-statistics-item .infographic-item-icon {
margin-bottom: 10px;
max-height: 60px; max-width: 60px;
}
@media screen and (max-width: 767px) { .infographic-statistics-item .infographic-item-icon { margin: 0 auto 10px; } }
.infographic-statistics-item .infographic-item-copy {
color: white;
font-family: 'Myriad Pro Regular', sans-serif; font-size: 15px; font-weight: 400;
line-height: 18px;
text-align: center; text-transform: uppercase;
}
@media screen and (max-width: 767px) {
.infographic-statistics-item .infographic-item-copy {
  margin: 0 auto;
  max-width: 85%;
}
}
#infographic-accidents-panel {
background-repeat: no-repeat; background-size: cover;
padding: 0 30px 400px;
}
@media screen and (max-width: 479px) { #infographic-accidents-panel { background-size: 200%; padding-bottom: 0; } }
@media screen and (min-width: 480px) and (max-width: 767px) { #infographics-accidents-panel { background-size: 100%; } }
@media screen and (max-width: 767px) { #infographic-accidents-panel { background-color: #286d98; background-position: center bottom; } }
#infographic-accidents-title {
color: white;
font-family: 'Source Sans Pro', sans-serif; font-size: 40px; font-weight: 900;
line-height: 40px;
margin-bottom: 30px;
text-align: center; text-shadow: 2px 2px #153c54; text-transform: uppercase;
}
#infographic-accidents-items {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
}
@media screen and (max-width: 479px) { #infographic-accidents-items { padding-bottom: 300px; } }
@media screen and (min-width: 480px) and (max-width: 767px) { #infographic-accidents-items { padding-bottom: 0; } }
.infographic-accidents-item { max-width: calc(25% - 15px); }
@media screen and (max-width: 479px) {
.infographic-accidents-item {
  margin-bottom: 30px;
  text-align: center;
  max-width: 100%;
}
}
@media screen and (min-width: 480px) and (max-width: 767px) { .infographic-accidents-item { max-width: calc(50% - 15px); } }
.infographic-accidents-item .infographic-item-icon { margin-bottom: 10px; }
@media screen and (max-width: 767px) { .infographic-accidents-item .infographic-item-icon { margin: 0 auto 10px; } }
.infographic-accidents-item .infographic-item-copy {
color: white;
font-family: 'Myriad Pro', sans-serif; font-size: 15px; font-weight: 400;
line-height: 18px;
text-align: center; text-transform: uppercase;
}
#infographic-accident-tables {
background: #333333;
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
padding: 30px 10px;
}
#infographic-road-conditions-table, #infographic-weather-conditions-table {
border-radius: 15px;
box-shadow: 0px 5px 15px black;
display: flex;
flex-direction: column; flex-wrap: wrap;
max-width: calc(50% - 7px);
text-align: center;
width: 100%;
}
@media screen and (max-width: 639px) { #infographic-road-conditions-table, #infographic-weather-conditions-table { max-width: 100%; } }
#infographic-road-conditions-table {
background: #f0932b;
}
#infographic-weather-conditions-table {
background: #eb4d4b;
}
@media screen and (max-width: 639px) { #infographic-weather-conditions-table { margin-top: 35px; } }
.infographic-table-icon { margin: -60px auto 30px; }
@media screen and (max-width: 639px) { .infographic-table-icon { max-width: 100px; } }
.infographic-figure-headers, .infographic-figure-list .infographic-figure-item,
.infographic-figure-totals .infographic-figure-item {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
padding: 0 15px;
}
.infographic-figure-headers .infographic-figure-item,
.infographic-figure-list .infographic-figure-item span,
.infographic-figure-totals .infographic-figure-item span {
color: white;
font-family: 'Myriad Pro', sans-serif; font-size: 15px; font-weight: 400;
line-height: 15px;
margin-bottom: 15px;
text-transform: uppercase;
width: calc(33% - 15px);
}
@media screen and (max-width: 359px) {
.infographic-figure-headers .infographic-figure-item,
.infographic-figure-list .infographic-figure-item span,
.infographic-figure-totals .infographic-figure-item span { font-size: 13px; line-height: 13px; }
}
.infographic-figure-headers .infographic-figure-item:first-of-type, .infographic-figure-label,
.infographic-figure-totals .infographic-figure-item span:first-of-type { text-align: left; }
.infographic-figure-totals { margin-bottom: 30px; margin-top: 10px; }
#infographic-table-disclaimers {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
}
#infographic-road-disclaimer, #infographic-weather-disclaimer {
color: white;
font-family: 'Myriad Pro', sans-serif; font-size: 14px; font-style: italic; font-weight: 700;
line-height: 18px;
margin-top: 15px;
text-align: center; text-transform: uppercase;
width: calc(50% - 15px);
}
#infographic-tips-panel { background-position-x: 3px; background-position-y: 24px; background-repeat: no-repeat; background-size: 104%; }
@media screen and (max-width: 989px) { #infographic-tips-panel { background-image: none !important; } }
#infographic-tips-header {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
padding: 10px;
}
#infographic-tips-title {
color: #00616f;
font-family: 'Source Sans Pro', sans-serif; font-size: 50px; font-weight: 900;
line-height: 50px;
max-width: calc(40% - 15px);
text-align: center; text-shadow: 0px 2px 5px #919191; text-transform: uppercase;
}
@media screen and (max-width: 767px) { #infographic-tips-title { margin: 0 auto; max-width: 100%; } }
#infographic-tips-title .ribbon-text {
background: #0096ad;
color: white;
display: block;
font-family: 'Source Sans Pro', sans-serif; font-size: 36px; font-weight: 900;
margin: 0 auto;
max-width: 80%;
position: relative;
text-shadow: none;
z-index: 1;
}
@media screen and (max-width: 767px) {
#infographic-tips-title .ribbon-text {
  margin: 10px auto 0;
  max-width: 100%;
  width: 300px;
}
}
@media screen and (max-width: 989px) {
#infographic-tips-title .ribbon-text {
  margin: 10px auto 0;
  max-width: 95%;
}
}
#infographic-tips-title .ribbon-text:after {
background-image: linear-gradient(to bottom right, #68c6d4, #68c6d4 50%, transparent 50%, transparent);
content: '';
display: block;
height: 34px; width: 50px;
position: absolute;
right: 0px;
top: 50px;
}
@media screen and (max-width: 989px) { #infographic-tips-title .ribbon-text:after { content: none; } }
#infographic-tips-description {
color: #bb6a4e;
font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 400;
max-width: calc(60% - 15px);
text-align: center;
}
@media screen and (max-width: 767px) { #infographic-tips-description { max-width: 100%; } }
#infographic-tips-description strong {
display: block;
margin-top: 15px;
}
#infographic-tips-description strong a {
color: #bb6a4e;
text-decoration: underline;
}
#infographic-tip-items {
display: flex;
flex-direction: row; flex-wrap: wrap;
justify-content: space-between;
margin-top: 30px;
}
@media screen and (max-width: 989px) { #infographic-tip-items { padding: 0 15px; } }
.infographic-tip-item { margin-bottom: 50px; }
.infographic-tip-item:nth-of-type(2n) { max-width: calc(45% - 15px); }
.infographic-tip-item:nth-of-type(2n+1) { max-width: calc(40% - 15px); }
@media screen and (min-width: 989px) {
.infographic-tip-item:nth-of-type(5) {
  align-items: center;
  display: flex;
  max-width: calc(47% - 15px);
  padding-left: 105px;
}
.infographic-tip-item:nth-of-type(6) {
  box-sizing: border-box;
  padding-left: 20px;
}
}
@media screen and (max-width: 639px) { .infographic-tip-item { max-width: 100% !important; } }
@media screen and (max-width: 989px) {
.infographic-tip-item {
  margin: 0 auto 50px;
  text-align: center;
}
}
.infographic-tip-icon { max-height: 56px; }
@media screen and (max-width: 989px) { .infographic-tip-icon { margin: 0 auto; } }
.infographic-tip-copy {
color: #00a3bc;
font-family: 'Myriad Pro', sans-serif; font-size: 15px;
line-height: 18px;
margin-top: 10px;
text-align: center; text-transform: uppercase;
}
@media screen and (max-width: 989px) {
.infographic-tip-copy {
  margin: 10px auto 0;
  max-width: 500px;
}
}
#infographic-footer {
border-top: 3px solid black;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
padding: 30px 15px;
}
@media screen and (max-width: 767px) {
#infographic-footer {
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
}
#infographic-footer-link { max-width: 400px; }
@media screen and (max-width: 767px) {
#infographic-footer-cta {
  margin-top: 30px;
  text-align: center;
}
}
#infographic-cta-btn {
color: #1a3d98;
font-family: 'Open Sans', sans-serif; font-size: 24px; font-style: italic; font-weight: bold;
}
#infographic-cta-address {
color: #000000;
font-family: 'Open Sans', sans-serif; font-size: 16px;
}