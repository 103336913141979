.dd {
  background: #000;
  margin: 0;
  padding: 0;
}

.dd * {
  font-family: "Lato", sans-serif;
  color: #fff;
}

.dd p {
  font-family: "Open Sans", sans-serif;
}

.dd .container {
  max-width: 1170px;
  margin: 0 auto;
  background: #333333;
}

.dd header {
  background: url(/wp-content/uploads/2018/12/dd-header.jpg) no-repeat top right;
  height: 430px;
  padding: 50px 30px 0;
  display: flex;
  align-items: flex-start;
  border: none;
}

.dd header h1 {
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 900;
  flex: 0 0 60%;
}

.dd section.sec-1 h2 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  margin-bottom: 30px;
}

.dd section.sec-1 {
  margin-top: -100px;
}

.dd section.sec-1 .flex {
  display: flex;
  height: 530px;
}

.dd section.sec-1 .flex > div {
  flex: 1;
  position: relative;
}

.dd section.sec-1 .b p {
  font-size: 16px;
  font-weight: 900;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  text-align: left;
}

.dd section.sec-1 p span {
  font-size: 10px;
  display: block;
  font-weight: 100;
  font-style: italic;
  line-height: 1;
}

.dd section.sec-1 .flex .tile {
  display: table;
}

.dd section.sec-1 .t {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dd section.sec-1 .t p {
  font-size: 26px;
  margin: 0 20px;
}

.dd .tile.t1 {
  position: absolute;
  right: 40px;
  top: 30px;
}

.dd .tile.t2 {
  position: absolute;
  right: 60px;
  top: 200px;
}

.dd .tile.t3 {
  position: absolute;
  left: -80px;
  bottom: 50px;
}

.dd .tile.t4 {
  position: absolute;
  right: -80px;
  bottom: 0
}

.dd .tile.t5 {
  position: absolute;
  left: -20px;
  top: 20px;
}

.dd .tile.t6 {
  position: absolute;
  left: 50px;
  top: 200px;
}

.dd section.sec-2 {
  margin-top: 30px;
}

.dd section.sec-2 > .flex {
  display: flex;
  height: 450px;
}

.dd section.sec-2 .l {
  background: url(/wp-content/uploads/2018/12/dd-map.jpg) top left no-repeat;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.dd section.sec-2 .flex > div {
  flex: 1;
  padding: 20px;
}

.dd section.sec-2 .l .flex {
  display: flex;
  flex: 0 0 70%;
  text-align: center;
  margin-top: -180px;
}

.dd section.sec-2 .l h3 {
  flex: 0 0 55%;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.5
}

.dd section.sec-2 .l .flex p {
  font-size: 16px;
  font-family: "Open Sans";
}

.dd .big-blue {
  font-size: 72px !important;
  font-family: "Lato";
  color: #26b6ff;
  font-weight: 900;
  text-shadow: 3.009px 3.993px 4px rgba(0, 0, 0, 0.68);
  padding: 0;
}

.dd section.sec-2 .r h3 {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: 900;
  line-height: 1.5
}

.dd section.sec-2 .r ul {
  margin-left: 25px;
  padding: 0;
  list-style: none;
}

.dd section.sec-2 .r li {
  font-size: 18px;
  font-family: "Open Sans";
  line-height: 1.5;
  padding-left: 25px;
  list-style: none;
  margin-bottom: 22px;
  background: url(/wp-content/uploads/2018/12/right-tri.png) no-repeat 0px 10px;
}

.dd section.sec-2 .l .flex > div:nth-child(1) {
  flex: 0 0 33%;
}

.dd section.sec-3 h2 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
}

.dd section.sec-3 .flex {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.dd section.sec-3 p {
  font-size: 16px;
  font-weight: 900;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  text-align: left;
  margin-top: 10px;
  text-align: center;
}

.dd footer {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.dd footer h4,
.dd footer h4 a {
  font-size: 24px;
  font-family: "Open Sans";
  color: #1a3d98 !important;
  font-style: italic;
  line-height: 1.2;
}

.dd footer h4 a:hover {
  color: #000 !important;
}

.dd footer address {
  font-size: 16px;
  font-family: "Open Sans";
  color: rgba(0, 0, 0, 0.702) !important;
  line-height: 1.2;
  font-style: normal;
}

.dd section.sec-4 {
  height: auto;
  background: url(/wp-content/uploads/2018/12/footer-top-bg.jpg) top center no-repeat;
  background-size: cover;
  padding: 150px 20px 100px;
  margin-top: -100px;
}

.dd section.sec-4 .flex {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.dd section.sec-4 .flex > div {
  flex: 0 0 55%;
  padding: 50px 20px;
}

.dd .gray-box {
  border-radius: 5px;
  background-color: rgb(92, 92, 92);
  box-shadow: 4.815px 6.389px 46px 0px rgba(0, 0, 0, 0.6);
  margin-bottom: 50px;
  text-align: center;
  margin-right: 280px;
}

.dd .blue-box {
  background-color: rgb(68, 108, 180);
  box-shadow: 4.815px 6.389px 46px 0px rgba(0, 0, 0, 0.6);
  margin-left: 100px;
  padding: 50px !important;
}

.dd .gray-box h2 {
  font-size: 35px;
  font-weight: 900;
  text-align: center;
}

.dd .gray-box p {
  font-size: 18px;
  font-family: "Open Sans";
  line-height: 1.5
}

.dd .blue-box h2 {
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
}

.dd .blue-box li {
  font-size: 18px;
  font-family: "Open Sans";
  line-height: 1.5;
  line-height: 1.5;
  padding-left: 25px;
  list-style: none;
  margin-bottom: 22px;
  background: url(/wp-content/uploads/2018/12/right-tri.png) no-repeat 0px 10px;
}

.dd .flex.one-three {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between !important;
}

.dd .flex.one-three h2 {
  flex: 0 0 25%;
}

.dd .flex.one-three p {
  flex: 0 0 65%;
  text-align: left;
  padding: 0;
}

@media screen and (max-width: 768px) {
  .dd header h1 {
    flex: 0 0 80%;
  }

  .dd .tile.t5 {
    left: 41px;
  }

  .dd .tile.t6 {
    left: 0;
  }

  .dd .tile.t4 {
    right: -200px;
  }

  .dd section.sec-2 > .flex {
    height: auto;
    flex-flow: row wrap;
  }

  .dd section.sec-2 .flex > div {
    flex: 0 0 100%;
  }

  .dd section.sec-2 .l {
    height: 400px;
  }

  .dd section.sec-4 .flex > div {
    flex: 0 0 60%;
  }

  .blue-box {
    margin: 0 !important;
    flex: 0 0 80% !important;
  }
}

@media screen and (max-width: 480px) {
  .dd header h1 {
    font-size: 32px;
    line-height: 1.25;
  }

  .dd section.sec-1 .t p {
    font-size: 14px;
    margin: 0 10px;
  }

  .dd section.sec-1 .b p {
    font-size: 12px;
    line-height: 1.2;
  }

  .dd .tile.t1 {
    right: 20px;
  }

  .dd .tile.t2 {
    right: 0px;
    top: 180px;
  }

  .dd .tile.t3 {
    bottom: 120px;
  }

  .dd .tile.t4 {
    right: auto;
    left: 85px;
  }

  .dd .tile.t6 {
    left: -30px;
  }

  .dd .tile.t5 {
    left: 10px;
  }

  .dd section.sec-2 .r ul {
    margin: 0;
  }

  .dd section.sec-2 .flex > div {
    flex: 1 !important;
    justify-content: center;
    flex-flow: row wrap;
  }

  .dd section.sec-3 .flex {
    flex-flow: column wrap;
  }

  .dd section.sec-4 .flex > div {
    padding: 20px;
    margin: 0;
    flex: 1 !important;
  }

  .dd section.sec-4 {
    height: auto;
    margin: 0;
    padding: 20px;
  }

  .dd section.sec-4 .flex > * {
    flex: 1 !important;
  }

  .dd section.sec-2 .l h3 {
    flex: 0 0 65%;
  }

  .dd section.sec-2 .l .flex {
    margin: 0 !important;
    flex: 1 !important;
  }

  @media screen and (max-width: 480px) {
    .dd header h1 {
      font-size: 32px;
      line-height: 1.25;
    }

    .dd section.sec-1 .t p {
      font-size: 22px;
      margin: 0 10px;
    }

    .dd section.sec-1 .b p {
      font-size: 12px;
      line-height: 1.2;
    }

    .dd .tile.t1 {
      right: 20px;
    }

    .dd .tile.t2 {
      right: 0px;
      top: 180px;
    }

    .dd .tile.t3 {
      bottom: 120px;
    }

    .dd .tile.t4 {
      right: auto;
      left: 85px;
    }

    .dd .tile.t6 {
      left: -30px;
    }

    .dd .tile.t5 {
      left: 20px;
    }

    .dd section.sec-2 .r ul {
      margin: 0;
    }

    .dd section.sec-2 .flex > div {
      flex: 1 !important;
      justify-content: center;
      flex-flow: row wrap;
    }

    .dd section.sec-3 .flex {
      flex-flow: column wrap;
    }

    .dd section.sec-4 .flex > div {
      padding: 20px;
      margin: 0;
      flex: 1 !important;
    }

    .dd section.sec-4 {
      height: auto;
      margin: 0;
      padding: 20px;
    }

    .dd section.sec-4 .flex > * {
      flex: 1 !important;
    }

    .dd section.sec-2 .l h3 {
      flex: 0 0 65%;
    }

    .dd section.sec-2 .l .flex {
      margin: 0 !important;
      flex: 1 !important;
    }
  }
}
