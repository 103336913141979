.pa-grid-wrap {

  .pa-grid {
    float: left;
    width: 45%;
    min-height: 240px;
    margin-bottom: 10px;
    margin-right: 5%;

    a {
      display: block;
      text-decoration: none;
      font-family: 'Oswald', sans-serif;
      font-weight: 300;
      font-size: 22px;
      color: #23356a;
      display: inline-block;
      padding: 10px;
      margin-bottom: 10px;

      &:hover {
        color: #456ce1;
      }

    }

    p {
      margin-bottom: 10px;
    }

  }

  @extend %clearfix;

}