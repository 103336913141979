#home-results {
  position: relative;

  @include media('<desktop') {
    background-color: $lavarich;
    border-top: 41px solid #c2cce5;
  }

  .home-team-results-bkg {

    @include media('>=desktop') {
      position: absolute;
      height: 340px;
      min-width: 600px;
      background-color: $lavarich;
      top: 0;
      right: 0;
    }

    @include media('>=desktop', '<widescreen') {
      height: 346px;
    }
  }

  .container {
    background-color: $lavarich;
    margin-top: -48px;
    border-left: 41px solid #c2cce5;

    @include media('<desktop') {
      border-left: none;
      margin-top: 0;
    }

    .btn {
      margin: 25px 0 30px 35px;

      @include media('<desktop') {
        margin-left: 0;
      }
    }

    .col-lg-4.awards-wrapper {

      @media screen and (min-width: 992px) {
        display: flex;
        align-items: center;
        margin-top: -50px;
      }

      @include media('<desktop') {
        padding-top: 15px;
        padding-bottom: 55px;
        text-align: center;

        &:before {
          height: 2px;
          width: 286px;
          background-color: #c2cce5;
          display: block;
          content: '';
          margin: 0 auto 56px;
          position: relative;
        }
      }
    }
  }
}

#home-results-alt {
  background-color: $lavarich;
  padding: 80px 0 70px;

  .home-results-wrapper {
    padding-top: 0px;

    &__item {
      @include media('<tablet') {
        padding: 0 30px;
      }

      @include media('>=desktop','<widescreen') {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .results-slider {
    justify-content: center;
  }

  .entry-content {
    margin-bottom: 40px;
  }
}

.home-results-wrapper {
  padding: 61px 0 0;
  position: relative;

  span {
    color: $light-blue;
    font-family: $font-work;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 3px;
    line-height: 32px;
    text-transform: uppercase;
    display: block;
    position: absolute;

    @include media('>=desktop') {
      top: 152px;
      left: -105px;
      transform: rotate(90deg);
    }

    @include media('<desktop') {
      top: -37px;
      text-align: center;
      width: 100%;
      left: 0;
    }
  }

  &__item {

    @include media('>=desktop') {
      border-right: 2px solid #b0bbd9;
      padding: 15px 60px;

      .page-template-practice-area & {
        padding: 7px 55px;
      }

    }

    @include media('>=desktop', '<widescreen') {
      padding-left: 0;
      padding-right: 0;
    }

    &:last-of-type {
      border-right: none;
    }

    .result-amount {
      color: $light-blue;
      font-family: $font-work;
      font-size: 80px;
      font-weight: 300;
      line-height: 58px;
      margin-bottom: 9px;

      .page-template-practice-area & {
        font-size: 65px;
      }

      @include media('>=desktop', '<widescreen') {
        font-size: 65px;
        line-height: 1;
      }

      &-alt {
        color: #666666;
        font-family: $font-work;
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 4px;
        line-height: 38px;
        text-transform: uppercase;

      }

    }

    .result-category {
      color: #666666;
      font-family: $default-font;
      font-size: 16px;
      line-height: 24px;

      .page-template-practice-area &,
      .single-attorney & {
        color: #666666;
        font-family: $font-work;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 3px;
        line-height: 30px;
        text-transform: uppercase;
        padding-top: 10px;
        margin-bottom: 20px;

        a {
          color: #666666;
        }
      }

    }

    .result-description {
      color: #666666;
      //font-size: 16px;
      //line-height: 24px;
      //padding-top: 15px;
      //max-width: 400px;
      //margin: 0 auto;

      a {
        color: $yellow;
        text-decoration:underline;
      }
    }

  }

}

#home-one {
  padding: 100px 0 151px;
  position: relative;

  @include media('<tablet') {
    padding: 30px 0;
  }

  @include media('>=tablet', '<desktop') {
    padding: 60px 0;
  }

  @include media('>=desktop') {
    background: linear-gradient(to right, white 0%, white 80%, $light-blue 80%, $light-blue 100%);
  }

  &:before {
    position: absolute;
    height: 100px;
    content: '';
    width: 100%;
    left: 0;
    top: 0;
    background-color: $white;

    @include media('<desktop') {
      display: none;
    }
  }

  &:after {
    position: absolute;
    height: 325px;
    content: '';
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: $white;
    z-index: 2;

    @include media('<desktop') {
      display: none;
    }

    @include media('>=desktop', '<widescreen') {
      height: 435px;
    }
  }

  .col-lg-6 {
    &:first-of-type {
      @include media('>=1344px') {
        padding-right: 85px;
      }

      img {
        @include media('<desktop') {
          margin-bottom: 30px;
        }
      }

      z-index: 3;
    }

    .alt-img-container {
      img {
        @include media('<desktop') {
          margin-bottom: 47px;
        }
      }
    }
  }

  .home-testimonials {
    position: relative;
    z-index: 3;
  }

}

#home-two {
  background-color: $light-grey;
  padding: 76px 0 50px;

  @include media('<tablet') {
    padding: 58px 0 30px;
  }

  @include media('>=tablet', '<desktop') {
    padding-top: 40px;
  }

  .col-xl-6 {
    h2 br {
      @include media('<1400px') {
        display: none;
      }
    }
  }

  .img-border {
    margin-bottom: 113px;

    @include media('<tablet') {
      margin-bottom: 47px;
    }

    @include media('>=tablet', '<desktop') {
      margin-bottom: 80px;
    }
  }

  .call-to-action {
    @include media('>=widescreen') {
      width: 496px;
    }
  }
}

#home-practice {
  padding: 60px 0;

  @include media('<tablet') {
    padding: 30px 0;
  }

  h2 {
    margin-bottom: 60px;

    @include media('<tablet') {
      margin-bottom: 28px;
    }
  }

  .col-lg-5 {
    background-color: $light-blue;
    padding-right: 0;
    padding-left: 0;

    @include media('<tablet') {
      margin-left: 10px;
      margin-right: 10px;
    }

    @include media('>=desktop') {
      border-width: 1px 0px 1px 1px;
      border-color: $blue;
      border-style: solid;
    }
  }

  .col-lg-7 {
    @include media('>=desktop') {
      padding-left: 0;
      background-color: $lavarich;
      border-width: 1px 1px 1px 0;
      border-color: $blue;
      border-style: solid;
    }

  }

  .nav-link,
  .ac-title-wrapper {
    background-color: $light-blue;
    color: $white;
    font-family: $font-work;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    height: 80px;
    text-transform: uppercase;
    border-radius: 0;
    padding: 24px 13px;
    border-top: 1px solid $blue;

    @include media('<tablet') {
      font-size: 15px;
      line-height: 21px;
      padding: 28px 13px;
    }

    @include media('<375px') {
      font-size: 14px;
    }

    @include media('<desktop') {
      position: relative;

      &:after {
        position: absolute;
        content: '';
        right: 0;
        top: 0;
        background-image: url("../../images/plus.jpg");
        width: 80px;
        height: 80px;
        -webkit-transition: background 0.3s;
        -moz-transition: background 0.3s;
        -o-transition: background 0.3s;
        transition: background 0.3s;
      }
    }

    a {
      color: $white;
      width: 100%;
      display: block;
    }

    img {
      margin-right: 15px;
      max-width: 40px;

      @include media('<tablet') {
        max-width: 32px;
      }
    }

    &:first-of-type {
      border-top: none;
    }

    &.active {
      @include media('<desktop') {
        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          background-image: url("../../images/close.jpg");
        }
      }

      @include media('>=desktop') {
        .home & {          
          background-color: $lavarich;
          color: $blue;
        }
      }
    }
  }

  .tab-pane,
  .panel-collapse {
    background-color: $lavarich;
    padding: 50px 37px 45px 50px;

    @include media('<tablet') {
      border-right: 1px solid $blue;
      border-left: 1px solid $blue;
      padding: 16px 15px 30px;
    }

    p {
      margin-bottom: 19px;
    }

    blockquote p {
      font-size: 18px;
    }

    .btn {
      margin-top: 0px;
      width: 100%;
      max-width: 340px;

      @include media('>=tablet', '<desktop') {
        max-width: 302px;
      }

      @include media('>=desktop', '<widescreen') {
        max-width: 218px;
      }

      @include media('>=widescreen', '<1400px') {
        max-width: 288px;
      }

      &:first-of-type {
        margin-right: 20px;

        @include media('<tablet') {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  .btn--2 {
    margin-top: 80px;

    @include media('<tablet') {
      margin-top: 36px;
    }
  }

  .card {
    border-color: #092249;
  }

  .accordion-pa-desktop {
    .nav-link,
    .ac-title-wrapper {
      img {
        &.hover {
          display: none;
        }
      }

      &.active {
        img {
          &.normal {
            display: none;
          }

          &.hover {
            display: inline-block;
          }
        }
      }
    }
  }

  .accordion-pa-mobile,
  .accordion-pa-archive-left,
  .accordion-pa-archive-middle,
  .accordion-pa-archive-right {
    .ac-title-wrapper {
      padding: 0;

      a {
        display: block;
        font-size: 14px;
        line-height: 21px;
        padding: 28px 13px;
        position: relative;

        &:after {
          position: absolute;
          content: '';
          right: 0;
          top: 0;
          background-image: url("../../images/close.jpg");
          width: 80px;
          height: 80px;
          -webkit-transition: background 0.3s;
          -moz-transition: background 0.3s;
          -o-transition: background 0.3s;
          transition: background 0.3s;
        }

        &.collapsed {
          &:after {
            position: absolute;
            content: '';
            right: 0;
            top: 0;
            background-image: url("../../images/plus.jpg");
          }
        }

      }

      &:after {
        display: none;
      }

      &.active {
        &:after {
          display: none;
        }
      }
    }
  }
}

#home-three {
  padding: 80px 0 197px;
  background-color: $blue;

  @include media('<tablet') {
    padding: 40px 0;
  }

  @include media('>=tablet', '<widescreen') {
    padding: 60px 0;
  }

  @include media('>=desktop') {
    background: url("../../images/bk-why-sansone.jpg") top left -366px;
    background-size: cover;
  }

  @include media('>=desktop', '<widescreen') {
    background-position: top center;
  }

  h2, p {
    color: $white;
  }

  h2 {
    @include media('<tablet') {
      padding: 0 65px;
    }
  }

  .btn {
    margin-top: 30px;

    @include media('<tablet') {
      margin-top: 15px;
    }
  }

  img {
    @media screen and (max-width: 767px) {
      max-width: 191px;
    }
  }
}

#home-team {
  padding-top: 100px;
  position: relative;
  //overflow: hidden;

  @include media('<tablet') {
    background-color: $light-grey;
    padding: 40px 0 0;
  }

  .section-subtitle {
    @include media('>=widescreen', '<1212px') {
      font-size: 38px;
    }

    @include media('<tablet') {
      text-align: center;
    }
  }

  .home-team-xtra-bkg {
    background-color: $light-grey;
    position: absolute;
    right: 0;
    top: -102px;
    height: 625px;
    min-width: 600px;

    @include media('<widescreen') {
      display: none;
    }

    @include media('>=widescreen', '<1400px') {
      height: 571px;
    }
  }

  .col-xl-8 {
    @include media('>=1400px') {
      padding-left: 0;
    }

    .img-border {
      margin-top: -202px;
      width: auto !important;

      @include media('>=tablet', '<widescreen') {
        margin-top: 80px;
      }

      @include media('<tablet') {
        margin-top: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  .team-extratitle {
    color: #666666;
    font-family: $font-work;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 5px;
    line-height: 38px;
    text-transform: uppercase;
    padding-left: 30px;
    border-left: 3px solid $yellow;
    padding-right: 62px;

    @include media('<tablet') {
      padding-left: 20px;
      padding-right: 0;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 30px;
      margin-left: 25px;
      max-width: 284px;
    }

    @include media('>=widescreen', '<1400px') {
      padding-right: 30px;
    }
  }
}

.attorney-wrapper {
  background-color: $light-grey;
  padding: 102px 0 74px 41px;
  position: relative;
  z-index: 1;

  &.attorney-wrapper-alt {
    background-color: $white;
    padding: 0 0 48px;
  }

  @include media('<tablet') {
    padding: 0;
  }

  @include media('>=tablet', '<desktop') {
    padding: 60px 30px;
  }

  @include media('>=desktop', '<1600px') {
    padding-left: 100px;
  }

  @include media('>=1600px') {
    justify-content: flex-end;
  }

  .attorney-item {
    @include media('<tablet') {
      margin-bottom: 45px;
    }

    a {
      position: relative;
      max-width: 330px;
      text-align: center;

      @include media('>=tablet') {
        display: block;
      }

      &.image-link:before {
        content: '';
        position: absolute;
        top: 0;
        z-index: 2;
        right: 0px;
        width: 44px;
        height: 44px;
        background-image: url("../../images/attorney-info.jpg");

        @media screen and (max-width: 767px) {
          right: 5px;
        }
      }

      img {
        width: auto !important;
      }
    }

    .attorney-block {
      margin-top: 18px;

      .attorney-title {
        color: $blue;
        font-family: $font-work;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
      }

      .attorney-position {
        margin-top: 9px;
        color: #666666;
        font-family: $font-work;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2px;
        line-height: 25px;
        text-transform: uppercase;
        padding: 0 25px;

        @include media('<tablet') {
          padding: 0 18px;
        }

        @include media('>=widescreen', '<1297px') {
          padding: 0;
        }

        @include media('>=1297px', '<1400px') {
          padding: 0 15px;
        }
      }
    }

  }
}

#home-four {
  padding: 120px 0 135px;

  @include media('<tablet') {
    padding: 30px 0 23px;
  }

  @include media('>=tablet', '<desktop') {
    padding: 60px 0;
  }

  .col-xl-6 {
    &:first-of-type {
      @include media('>=widescreen') {
        padding-right: 35px;
      }

      @include media('>=1400px') {
        padding-right: 65px;
      }

      img {
        @include media('<tablet') {
          width: 90%;
        }

        @include media('<widescreen') {
          margin-bottom: 40px;
        }

        @include media('>=widescreen') {
          padding-top: 26px;
        }
      }
    }

    &:last-of-type {
      @include media('>=1400px') {
        padding-right: 24px;
      }

      h2 br {
        @include media('<widescreen') {
          display: none;
        }
      }

      li {
        @include media('>=1400px') {
          padding-right: 92px;
        }
      }
    }
  }
}

#home-five {
  padding: 84px 0 178px;
  background-color: $light-grey;

  @include media('<desktop') {
    padding: 58px 0 40px;
  }

  .col-xl-6 {
    &:first-of-type {
      @include media('>=widescreen') {
        padding-right: 135px;
      }

      h2 br {
        @include media('<1400px') {
          display: none;
        }
      }

      .btn {
        margin-top: 23px;

        @include media('<tablet') {
          width: 300px;
        }
      }
    }

    &:last-of-type {
      .img-border {

        @include media('<tablet') {
          margin-bottom: 50px;
        }

        @include media('<widescreen') {
          margin-bottom: 70px;
        }
      }
    }
  }
}