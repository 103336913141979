.page-template-practice-area {

  #home-five {
    padding-bottom: 84px;

    .call-to-action {
      margin-top: 112px;

      @include media('<tablet') {
        margin-top: 40px;
      }

      @include media('<widescreen') {
        margin-bottom: 40px;
      }

    }

    .img-border {
      @include media('>=desktop', '<1527px') {
        margin-left: 0 !important;
      }
    }

  }

}

#single-practice-one {
  padding-bottom: 40px;
}

.crashes_repeater {
  &__item {
    margin-bottom: 105px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .crash-image {
      width: 100px;
      float: left;
      text-align: center;

      img {
        max-width: 75px;
      }
    }

    .crash-content {
      float: left;
      width: calc(100% - 100px);
      border-left: 3px solid $yellow;
      padding-left: 15px;

      ul {
        display: flex;
        margin-bottom: 5px;
        flex-direction: column;

        @include media('>=tablet') {
          flex-direction: row;
          align-items: center;
        }

        li {
          color: $light-blue;
          font-family: $font-work;
          font-size: 18px;
          font-weight: 600;
          line-height: 21px;
          text-transform: uppercase;

          strong {
            font-size: 60px;
            font-weight: 300;
            line-height: 60px;
            text-transform: uppercase;
            margin-right: 10px;
          }
        }
      }

      p {
        color: $text;
        font-family: $default-font;
        font-size: 18px;
        line-height: 30px;
      }
    }
  }
}

#single-practice-three {
  padding: 60px 0 74px;

  @include media('<tablet') {
    padding-bottom: 30px;
  }

  .single-four-item {
    padding-left: 123px;
    padding-right: 123px;
    padding-top: 44px;
    padding-bottom: 15px;

    @include media('<desktop') {
      padding-left: 20px;
      padding-right: 20px;
    }

    &:nth-child(even) {
      background-color: $light-grey;
      padding-top: 75px;
      padding-bottom: 45px;
    }
  }
}

#big-call-to-action {
  padding: 70px 0 80px;

  @include media('>=tablet', '<desktop') {
    padding: 40px 0;
  }

  .col-xl-10 {
    background-image: url("../../images/cta-banner.jpg");

    @include media('>=widescreen', '<1400px') {
      max-width: 95% !important;
      flex: 0 0 95% !important
    }

    .row {
      height: 230px;
      align-items: center;
      position: relative;

      @include media('<tablet') {
        height: 380px !important;
      }

      @include media('<desktop') {
        height: 280px;
      }

      &:before {
        @include media('>=desktop') {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          background-image: url("../../images/attorneys.png");
          width: 368px;
          height: 300px;
        }
      }

      .call-to-action {

        @include media('>=desktop', '<widescreen') {
          padding-left: 20px;
        }

        p, a, span {
          color: $white;
        }

        p {
          line-height: 1;
          font-size: 18px;

          @include media('>=desktop', '<widescreen') {
            font-size: 16px;
          }

        }

        a {
          margin-bottom: 0;
          font-size: 60px;

          @include media('<tablet') {
            font-size: 45px;
          }

          @include media('>=desktop', '<widescreen') {
            font-size: 38px;
          }

        }

        span {
          font-size: 18px;
        }

        .btn {
          font-size: 18px !important;
        }

      }

      .col-lg-5 .btn {

        @include media('>=desktop') {
          display: none;
        }


      }

      .btn.btn--1 {
        @include media('>=desktop', '<widescreen') {
          padding: 20px 20px;
        }
      }

    }

  }

}


#single-practice-five {
  padding-top: 0;
  padding-bottom: 110px;

  @include media('<tablet') {
    padding-bottom: 50px;
  }

}