.page-template-template-new-client-info {

  .inner-content.container {
    padding-bottom: 140px;

    .row {
      display: block;
    }

    .btn.btn-lg {
      margin-top: 25px;

      &:hover {
        background: #2c4695 !important;
      }

    }

    #prior_accidents_and_injuries {
      @include media('<tablet') {
        margin-top: 30px;
      }
    }

    .form-horizontal .form-group {
      @include media('<tablet') {
        margin-right: 0 !important;
        margin-left: 0 !important;
      }
    }

  }

}