.post-type-archive-testimonial,
.page-template-archive-testimonial {

  .main-content {
    background-image: none;

    @include media('<tablet') {
      padding-bottom: 60px;
    }

    .content {

      .testimonial {
        background-color: $lavarich;
        border: 1px solid $border-color;
        padding: 27px;
        margin-bottom: 30px;

        .sidebar-title {
          color: $blue;
        }

        blockquote {
          border-left: none !important;

          q p {
            border-left: none !important;
            padding-left: 0px !important;
            font-size: 18px !important;
          }
        }
      }
    }
  }
}