.page-template-page-driving-near-trucks {
	.container {
		@extend %clearfix;
	}
}

main#dnt .container {
    margin: auto;
    background-color: #1f1f1f !important;
    padding: 20px 0 15px 25px;
    font-family: Lato;
    color: white;
  }
  main#dnt .left {
    float: left;
  }
  main#dnt .right {
    float: right;
  }
  main#dnt #dnt-header .container {
    background: url('../../images/driving-near-trucks/trucks.png') no-repeat top right;
    height: 250px;
  }
  main#dnt #dnt-header h1 {
    padding-top: 45px;
    color: #d9363a;
    font-size: 65px;
    font-family: lato, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
  }
  main#dnt #myths .container{
    color: white;
    background: url('../../images/driving-near-trucks/myth-bg.png') no-repeat right;
  }

  main#dnt h2 {
    padding-top: 25px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
  }
  main#dnt #myths a {
    color: white;
  }
  main#dnt #myths .myth-txt {
    width: 51%;
  }
  main#dnt #myths li {
    list-style: none;
    padding: 15px 0 15px 90px;
    font-size: 18px
  }
  main#dnt #myths li.myth {
    background: url('../../images/driving-near-trucks/myth.png') no-repeat left;
  }
  main#dnt #myths li.fact {
    background: url('../../images/driving-near-trucks/fact.png') no-repeat left;
  }
  main#dnt #facts .container {
    background: url('../../images/driving-near-trucks/truck.png') no-repeat 25px 120px,
    url('../../images/driving-near-trucks/facts-bg.png') no-repeat center;
  }
  main#dnt #facts .right {
    width: 51%;
    padding-top: 65px;
  }
  main#dnt #facts .facts-itms {
    display: flex;
    justify-content: space-around;
    padding-bottom: 20px;
  }
  main#dnt #facts .facts-itms img {
    height: 25%
  }
  main#dnt #facts .facts-itms p {
    width: 59%;
  }
  main#dnt #tips .container {
    position: relative
  }
  main#dnt #tips .tips-txt {
    width: 51%;
    padding: 0 45px 20px 28px;
    background-color: #3e3e3e;
    border-radius: 5px;
  }
  main#dnt #tips li.dont {
    background: url('../../images/driving-near-trucks/dont.png') no-repeat left;
  }
  main#dnt #tips li.do {
    background: url('../../images/driving-near-trucks/do.png') no-repeat left;
  }
  main#dnt #tips li {
    list-style: none;
    padding: 15px 0 15px 40px;
    font-size: 18px
  }
  main#dnt #tips img.right {
    position: absolute;
    top: 36px;
    right: 0;
  }
  main#dnt #gray-sec .container {
    background: #333333 !important;
    height: 30px;
  }
  main#dnt #cta .container {
    background: white !important;
    border-top: 3px solid black;
    border-bottom: 3px solid black;

    .image-container {
    	display: inline-block;
    	@extend %clearfix;
    }
  }
  main#dnt #cta .right {
    padding-right: 20px;
  }
  main#dnt #cta .right h3 {
    font-family: sans-serif;
    font-size: 24px;
    font-style: italic;
    line-height: 1;
    color: #1a3d98;
  }
  main#dnt #cta .right a {
    color: #1a3d98;
  }
  main#dnt #cta .right p {
    color: #4c4c4c;
    font-size: 16px;
    font-family: sans-serif;
    line-height: 1;
  }
  @media (min-width: 992px){
    main#dnt .container {
      width: 950px;
    }
  }

  @media (max-width: 992px){
    main#dnt .container {
      background-size: 50% !important;
    }
    main#dnt .container {
      padding: 20px 0 15px 10px;
    }
    main#dnt #dnt-header .container {
      height: 200px;
    }
    main#dnt #facts .container {
      background: url('../../images/driving-near-trucks/truck.png') no-repeat 25px 120px,
      url('../../images/driving-near-trucks/facts-bg.png') no-repeat 100% !important;
      background-size: 50%, 100%;
      background-color: #1f1f1f !important;
    }
    main#dnt #tips img.right {
      width: 40%;
    }
  }
  @media (max-width: 900px){
    main#dnt #dnt-header h1 {
      font-size: 7vw;
    }
    main#dnt h2 {
      font-size: 5vw
    }
  }
  @media (max-width: 767px){

    main#dnt #myths .container {
      background: url('../../images/driving-near-trucks/myth-bg.png') no-repeat top;
      background-size: 40% !important;
    }
    main#dnt #myths h2 {
      padding-top: 220px;
    }
    main#dnt #myths .myth-txt {
      width: 90%;
    }
    main#dnt #facts .container {
          background: #1f1f1f url('../../images/driving-near-trucks/truck.png') no-repeat top !important;
          background-size: 33% !important;
    }
    main#dnt #facts h2{
      padding-top: 315px;
    }
    main#dnt #facts .right {
      width: 100%;
      padding-top: 25px;
    }
    main#dnt #tips .tips-txt {
      width: 80%;
      padding: 0 45px 126px 28px;
    }
    main#dnt #tips img.right {
      display: none;
    }
  }
  @media (max-width: 500px) {
    main#dnt #dnt-header h1 {
      font-size: 50px;
      line-height: 1;
      padding-top: 120px;
      text-align: center;
    }
    main#dnt h2 {
      font-size: 30px;
    }

    main#dnt #facts .container {
          background: #1f1f1f url('../../images/driving-near-trucks/truck.png') no-repeat top !important;
          background-size: 50% !important;
    }
    main#dnt #facts h2{
      padding-top: 315px;
    }

    main#dnt #cta .left, main#dnt #cta .right {
      float: none;
      text-align: center;
    }

  }

  @media (max-width: 480px) {
    main#dnt .container {
      padding: 20px 0 15px 9px;
    }
    main#dnt #dnt-header .container {
      background-size: 80% !important;
    }
    main#dnt #dnt-header h1 {
      padding-top: 165px;
    }
    main#dnt #myths h2 {
      padding-top: 125px;
    }
    main#dnt #facts h2 {
      padding-top: 250px;
    }
    main#dnt #tips .tips-txt {
      padding: 0 27px 126px 27px
    }

  }