/*.page-id-19004 {

  .crashes_repeater {
    display: none;
  }

}*/

.main-content {
  padding-top: 60px;
  padding-bottom: 160px;

  .error404 &,
  .page-template,
  .page-template-default &,
  .blog &,
  .single &,
  .page-template-archive-result &,
  .post-type-archive-testimonial &,
  .post-type-archive-attorney & {
    @include media('<tablet') {
      padding-bottom: 30px;
    }

  }

  .page-template {

    @include media('<tablet') {
      padding-top: 45px;
    }

  }

}

.img-border {
  position: relative;
  z-index: 2;

  @include media('>=desktop', '<1528px') {
    width: 100%;
  }

  &--top-left {
    margin-top: -156px;

    @include media('<desktop') {
      margin-top: 0;
    }

    @include media('>=1528px') {
      margin-left: -70px;
    }

    @include media('>=desktop', '<1528px') {
      margin-left: -37px;
    }
  }

  &--top-right {
    margin-top: -156px;

    @include media('<desktop') {
      margin-top: 0;
    }

    @include media('>=desktop', '<widescreen') {
      margin-top: -47px;
    }

    @include media('>=desktop', '<1344px') {
      width: 95%;
    }

    @include media('>=desktop') {
      margin-left: 37px;
    }
  }

  img {
    position: relative;
    z-index: 1;

    @include media('>=desktop', '<1528px') {
      width: 100%;
    }

    @include media('>=410px', '<tablet') {
      width: 100%;
    }
  }

  &:after {
    position: absolute;
    content: '';
    height: 300px;
    z-index: 0;
    width: 300px;
    background-image: url("../../images/img-back.png");

    @include media('<tablet') {
      width: 240px;
      height: 240px;
    }

  }

  &--sright {
    &:after {
      right: 0px;
      bottom: -37px;

      @include media('<tablet') {
        right: -7px;
        bottom: auto;
        top: -18px;
      }

      @include media('>=tablet', '<1528px') {
        right: -37px;
      }
    }
  }

  &--sleft {
    &:after {
      left: 0px;
      bottom: -37px;

      @include media('<tablet') {
        right: -7px;
        bottom: auto;
        left: auto;
        top: -18px;
      }

      @include media('>=tablet') {
        left: -40px;
      }
    }
  }
}

// Blue Image container (used in homepage section one)
.alt-img-container {
  position: relative;

  &:after {
    content: '';
    height: 261px;
    width: 110px;
    background-color: $blue;
    right: -7px;
    top: -30px;
    position: absolute;
    z-index: -1;
  }
}

.content-wrapper,
.main-content {
  // Removed, unless needed elsewhere
  .container {

    .row {

      @include media('>=widescreen') {

        > .entry-content.col-xl-8,
        > .content.col-xl-8 {
          padding-right: 100px;
        }

      }

    }

  }
}


[v-cloak] > * {
  display: none;
}

#loader-div {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  overflow: hidden;
  transition: all 0.5s ease;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 77px;
    height: 77px;
    @include vertical-align();
    margin: 0 auto;
    text-align: center;
    display: block;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    margin: 6px;
    border: 7px solid $light-blue;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $light-blue transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.arrow-link {
  color: $orange;
  font-size: 16px;
  font-weight: 600;
  background-repeat: no-repeat;
  background-size: 18px 8px;
  padding-right: 22px;
  background-position: right center;

  &__alt {
    color: $white;
  }
}

.video-question {
  .modal-header {
    border-bottom: 0;
  }

  .modal-body {
    padding-top: 0;
  }
}

// Call To Action
.call-to-action {
  p {
    color: $light-blue;
    font-family: $font-work;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3.33px;
    line-height: 38px;
    text-transform: uppercase;
    margin-bottom: 10px;

    @include media('<tablet') {
      font-size: 16px;
      line-height: 34px;
      letter-spacing: 1px;
    }

    @include media('>=desktop', '<widescreen') {
      font-size: 18px;
      line-height: 36px;
    }
  }

  span {
    display: block;
    color: $text;
    font-family: $default-font;
    font-size: 24px;
    line-height: 30px;
    font-style: italic;
  }

  .phone {
    color: $light-blue;
    font-family: $font-work;
    font-size: 70px;
    font-weight: 700;
    line-height: 1;
    display: block;
    margin-bottom: 20px;

    @include media('<tablet') {
      font-size: 48px;
    }

    @include media('>=desktop', '<widescreen') {
      font-size: 63px;
    }
  }

  .btn {
    margin-top: 26px;

    @include media('<tablet') {
      width: 300px;
    }
  }
}

/*.slick-dots {
    text-align: center;
    padding: 0;
    margin-top: 17px;

    li {
        display: inline-block;
        margin: 0 5px;
        vertical-align: middle;
    }
    button {
        text-indent: -9999px;
        background-color: #e1e8ef;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: block;
        outline: none;
        padding:0;
        border:none;
    }

    .slick-active {
        button {
            background-color: $cerulean;
        }
    }
}*/

.custom-nav-testimonials,
.custom-nav-results {

  .slick-arrow {
    width: 30px;
    height: 54px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-color: transparent;

    @include media('<tablet') {
      background-size: 80% 80%;
    }

    &.slick-prev {
      left: 38px;
      background-image: url("../../images/prev.png");

      @include media('<tablet') {
        left: 5px !important;
      }
    }

    &.slick-next {
      right: 38px;
      background-image: url("../../images/next.png");

      @include media('<tablet') {
        right: 5px !important;
      }
    }
  }
}

.custom-nav-testimonials {

  .slick-arrow {
    &.slick-prev {
      background-image: url("../../images/prev-test.png");
    }

    &.slick-next {
      background-image: url("../../images/next-test.png");
    }
  }
}


#hoursModal {

  .modal-header,
  .modal-body {

    background-color: $blue;

    h5 {
      font-weight: 700;
      color: $white;
    }
  }

  .modal-body {
    padding-top: 20px;
  }

  .opening-hours {
    table {
      width: 100%;

      td {
        border: none;
        color: $white;

        span {
          float: right;
          font-weight: 700;
        }
      }
    }
  }
}

// Lists
.lblue-list li {
  background: #f2f7ff !important;
  padding: 12px 5px 10px !important;
  margin-bottom: 10px !important;
}

.hit-run-1 {
  padding-left: 0;

  li {
    position: relative;
    min-height: 58px;
    padding: 12px 5px 10px 75px !important;

    &:before {
      width: 61px;
      height: 61px;
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      display: inline-block;
      background: url('../../images/hit-list-sprite.png') no-repeat;
      overflow: hidden;
      text-indent: -9999px;
      text-align: left;
      margin-top: 16px;
      margin-left: 5px;
    }

    &:after {
      display: none;
    }

    &.hit-1a:before {
      background-position: -10px -0px;
    }

    &.hit-1b:before {
      background-position: -10px -71px;
    }

    &.hit-1d:before {
      background-position: -10px -142px;
    }

    &.hit-1e:before {
      background-position: -10px -213px;
    }

    &.hit-1c:before {
      margin-top: 8px;
      background-position: -10px -284px;
      width: 60px;
    }
  }
}

.mot-list-1 {
  padding-left: 0;

  li {
    position: relative;
    min-height: 58px;
    padding: 12px 5px 10px 75px !important;

    &:after {
      display: none;
    }

    &:before {
      width: 61px;
      height: 61px;
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      display: inline-block;
      background: url('../../images/mot-list-sprite.png') no-repeat;
      overflow: hidden;
      text-indent: -9999px;
      text-align: left;
      margin-top: 16px;
      margin-left: 5px;
    }

    &.mot-1a:before {
      background-position: -10px -0px;
    }

    &.mot-1b:before {
      background-position: -81px -0px;
    }

    &.mot-1c:before {
      background-position: -152px -0px;
    }

    &.mot-1d:before {
      background-position: -10px -71px;
    }

    &.mot-1e:before {
      background-position: -81px -71px;
    }

    &.mot-1f:before {
      background-position: -152px -71px;
    }

    &.mot-1g:before {
      background-position: -10px -142px;
    }

    &.mot-1h:before {
      background-position: -81px -142px;
    }

    &.mot-1i:before {
      background-position: -152px -142px;
    }

    &.mot-1j:before {
      background-position: -10px -213px;
    }
  }
}

.total_votes img {
  float: left;
}

.vote_count {
  font-weight: 400;
  font-size: 30px;
  color: #1a3173;
  margin: 0;
  padding: 15px;
  display: inline-block;
}

.vote-content a {
  cursor: pointer;
  display: block;
}

.wp-social-login-provider-list a {
  padding-right: 5px;
  float: left;
}