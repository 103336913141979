.page-template-page-fatal-pedestrian-accidents {

  .wrapper {
    width: 960px;
    margin: auto;
    position: relative;
  }

  /* Large desktop */
  @media (max-width: 1200px) {

  }
  @media (max-width: 960px) {

  }

  @media (max-width: 979px) {

    .wrapper {
      margin: 0 3%;
      width: auto;
    }

    .col3 .col-box {
      padding-left: 12px;
    }

    .bottom-box ul li {
      padding-left: 12px;
    }

  }

  .fpa header {
    background: #151515;
    border-top: 10px solid #26b6ff;
    padding: 30px;
  }

  .fpa * {
    font-family: Lato;
    font-weight: bold;
  }

  .fpa header .flex {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .fpa header h1 {
    font-weight: 800;
    font-size: 60px;
    line-height: 67px;
    text-transform: uppercase;
    color: white;
  }

  .fpa header .l {
    flex: 0 0 30%;
    margin-right: 30px;
  }

  .fpa section.sec1 {
    background: #212020;
  }

  .fpa section * {
    color: #fff;
  }

  .fpa .sec2, .fpa .sec3, .fpa .sources {
    background-color: #252424;
  }

  .fpa h2 {
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    font-size: 36px;
    width: 80%;
    margin: 0 auto;
  }

  .fpa .sec1, .fpa .sec2 {
    padding: 30px 0;
  }

  .fpa .sec1 .flex, .fpa .sec2 .flex {
    display: flex;
  }

  .fpa .sec1 p, .fpa .sec2 p {
    text-transform: uppercase;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    line-height: 1.2;
  }

  .fpa .sec2 p {
    font-size: 18px;
    text-align: left;
  }

  .fpa .sec1 h3 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 32px;
  }

  .fpa .sec1 .flex > div {
    padding: 0 25px;
  }

  .fpa .sec1 .flex .flex-c {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 110px;
  }

  .fpa .sec1 .flex h3 {
    margin-left: 20px;
  }

  .fpa .sec2 .flex .flex-c {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 110px;
  }

  .fpa .sec2 .flex .flex-c h3 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 32px;
    margin-left: 20px
  }

  .fpa .sec2 .flex .flex-c h4 {
    font-weight: 800;
    text-transform: uppercase;
    font-size: 22px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .fpa .sec2 .flex > div {
    padding: 0 20px;
  }

  .fpa .sec2 .lower {
    height: 435px;
    background: url(/wp-content/uploads/2019/03/sec3-map-bg.png) left top no-repeat;
    margin: 30px 0;
    display: flex;
    flex-flow: column;
    justify-content: space-evenly;
  }

  .fpa .sec2 .lower .flex {
    display: flex;
  }

  .fpa .sec2 .lower .flex > div {
    flex: 1;
  }

  .fpa .sec2 .lower .flex:nth-child(1) img {
    margin-left: 85px;
  }

  .fpa .sec2 .lower .flex:nth-child(2) img {
    margin-left: 160px;
  }

  .fpa .sec2 .lower .flex:nth-child(3) img {
    margin-left: 240px;
  }

  .fpa .sec2 .lower .flex .r {
    position: relative;
  }

  .fpa .sec2 .lower .flex .r:before {
    content: "";
    height: 2px;
    background: #fff;
    position: absolute;
    top: 40%;
    right: 100%;
  }

  .fpa .sec2 .lower .flex:nth-child(1) .r:before {
    width: 250px;
  }

  .fpa .sec2 .lower .flex:nth-child(2) .r:before {
    width: 220px;
  }

  .fpa .sec2 .lower .flex:nth-child(3) .r:before {
    width: 170px;
  }

  .fpa .blue-bg {
    background-color: #446cb4;
    box-shadow: 4.815px 6.389px 46px 0px rgba(0, 0, 0, 0.6);
    padding: 40px 20px;
    width: 60%;
  }

  .fpa .blue-bg h2 {
    text-align: left;
    width: 100%;
  }

  .fpa .blue-bg ul li {
    list-style: none;
    background: url(/wp-content/uploads/2019/03/fpa-list.png) 0px 8px no-repeat;
    margin-bottom: 20px;
    padding-left: 20px;
  }

  .fpa .blue-bg ul {
    padding-left: 30px;
  }

  .fpa .sec3 {
    background: url(/wp-content/uploads/2019/03/sec4-bg.jpg) center right no-repeat;
  }

  .fpa .sources {
    padding: 20px;
  }

  .fpa .sources h4 {
    font-size: 20px
  }

  .fpa .sources li {
    list-style: none;
    font-style: italic;
  }

  .fpa footer {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fpa footer .r {
    flex: 0 0 35%;
  }

  .fpa footer h3 {
    font-size: 24px;
    font-style: italic;
    line-height: 1.3;
  }

  .fpa footer h3 a {
    display: block;
    color: #213167;
  }

  .fpa footer address {
    font-size: 18px;
  }

  .phone-info-top {
    margin-top: 15px;
  }

  .phone-info-top a {

    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 23px;
    color: #fff;
    background: #1a3173;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    display: block;
    text-align: center;
    padding: 10px 0;
    max-width: 80%;
    margin: 0 auto 15px;

  }

  .phone-info-top .phone-top {
    background: #f93;
  }

  @media (max-width: 800px) {

    header {
      padding-bottom: 25px;
    }

    .home header {
      margin-bottom: 0px;
    }

  }

  @media screen and (max-width: 768px) {

    .fpa footer {
      flex-flow: column;
      text-align: center;
    }

  }

  @media screen and (max-width: 767px) {
    .fpa .flex {
      flex-flow: column;
      text-align: center;
    }

    .fpa .sources li {
      word-break: break-all;
      margin-bottom: 20px;
    }

    .fpa .blue-bg {
      width: 70%;
    }
    .fpa .blue-bg {
      width: auto;
      padding: 30px 20px !important;
    }
    .bottom-area .social-media ul {
      display: flex;
    }

    .bottom-area .social-media ul li {
      width: auto;
    }
  }


  @media (max-width: 600px) {

    header {
      padding-bottom: 25px;
    }

    .home header {
      margin-bottom: 0px;
    }

  }

  .fpa {
    margin: 50px auto;
  }

  .list-about .atty-list {
    display: block;
    text-align: center;
  }

  .list-about .atty-list li {
    display: inline-block;
    width: 32%;
    float: none;
    vertical-align: top;
    text-align: center;
    padding: 0px;
    margin: 0 auto 20px 2%;
  }

  .list-about .atty-list li:first-child {
    padding-left: 0;
    margin-left: auto;
  }

  .fpa footer {
    padding: 18px 0 16px 0;
    position: relative;
    margin-bottom: 120px;
  }

}

