.btn {
  display: inline-block;
  border: 0;
  box-shadow: none;
  @extend %transition;
  font-weight: 700;
  cursor: pointer;
  line-height: normal;
  text-align: center;

  &.btn--1 {
    font-family: $font-work;
    font-size: 18px;
    color: $white;
    border-radius: 4px;
    background-color: $yellow;
    padding: 20px 46px;
    text-decoration: none;

    &:hover {
      background-color: #ffd73a;
      color: $black;
    }
  }

  &.btn--2 {
    font-family: $font-work;
    font-size: 18px;
    color: $light-blue;
    border-radius: 4px;
    border: 2px solid $light-blue;
    background-color: transparent;
    padding: 20px 46px;

    &:hover {
      background-color: $light-blue;
      color: $white;
    }
  }
}