.page-template-archive-pa {
  .main-content {
    padding-top: 0px;

    .practice-areas-wrapper {
      margin-bottom: 30px;
      margin-top: 40px;
    }

    @include media('<tablet') {
      padding-bottom: 30px;
    }
  }

  #home-practice {
    padding-bottom: 100px;

    @include media('<widescreen') {
      padding-bottom: 30px;
    }

    h2 {
      margin-bottom: 30px;
    }

    .card {
      margin-bottom: 30px;

      .ac-title-wrapper a {
        font-size: 18px !important;
        line-height: 33px !important;

        @include media('<tablet') {
          font-size: 16px !important;
          line-height: 31px !important;
        }

        @include media('<450px') {
          font-size: 14px !important;
          display: flex;
          padding: 12px 88px 0 0;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px !important;

          img {
            margin-right: 0;
          }
        }

        @include media('>=widescreen', '<1400px') {
          font-size: 13px !important;
          line-height: 31px !important;
        }
      }

      .panel-collapse {
        padding: 25px 17px 25px 25px;

        .bottom-links a {
          max-width: 160px;
          padding-left: 29px;
          padding-right: 29px;
          margin-bottom: 0;
        }
      }
    }
  }
}

#archive-practice-two {
  padding-top: 82px;
  background-color: $light-grey;
  position: relative;

  @include media('<widescreen') {
    padding-bottom: 100px;
  }

  @include media('<tablet') {
    padding-bottom: 50px;
    padding-top: 40px;
  }

  &:after {
    @include media('>=widescreen') {
      background-color: $white;
      height: 172px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 0;
    }

  }

  .col-xl-6 {
    &:first-of-type {
      @include media('>=widescreen') {
        padding-right: 41px;
      }

      .home-testimonials {
        margin-top: 20px;
        z-index: 2;
        position: relative;

        @include media('<widescreen') {
          padding-bottom: 100px;
        }
      }
    }

    .call-to-action {
      margin-top: 182px;

      @include media('<widescreen') {
        margin-top: 100px;
      }

      @include media('<tablet') {
        margin-top: 50px;
      }
    }
  }

  @include media('>=1200px', '<1500px') {

    .img-border--top-right {
      margin-left: auto;
    }

  }

}

#practice-team {
  padding: 100px 0 0;

  @include media('<tablet') {
    padding-top: 50px;
  }

  .section-subtitle {
    margin-bottom: 50px;
  }
}