.single-post {

  .blog-post {

    .post-wrap {

      .image-holder {
      	@include media('>=tablet','<desktop') {
      		padding-top: 10px;
      	}
      }

      .post-meta {
        margin-bottom: 15px;
          
        a {
            color: $blue;
        }
      }

    }

  }

}