.top-header-mobile {
  height: 80px;

  .site-logo-wrap {
    padding-top: 15px;

    img {
      max-width: 258px;
    }
  }
}

.top-header-desktop {
  background-color: $light-grey;

  @include media('>=desktop') {
    height: 150px;
    background: linear-gradient(to left, $light-grey, $light-grey 75%, $white 75%);
  }

  .site-logo-wrap {
    background-color: $white;
    padding-top: 17px;
    height: 150px;

    @include media('>=widescreen') {
      text-align: left !important;
    }

    span {
      color: $text;
      font-family: $default-font;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      display: block;
      margin-top: 9px;
    }

    img {
      @include media('>=desktop', '<widescreen') {
        max-width: 350px;
      }
    }
  }

  .col-lg-7 {
    @include media('>=desktop', '<widescreen') {
      padding: 0;
    }

    .call-to-action-header {
      padding-top: 30px;
      text-align: right;
      color: $text;
      font-family: $default-font;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 5px;
      text-transform: uppercase;

      span {
        color: $light-blue;
        font-size: 30px;
        font-weight: 700;
        line-height: 30px;
        vertical-align: bottom;
        letter-spacing: 0;

        @include media('>=desktop', '<widescreen') {
          font-size: 27px;
          line-height: 27px;
        }
      }
    }
  }

  .main-menu {
    width: 100%;

    .nav {
      flex-direction: row;
      justify-content: flex-end;
      padding-top: 42px;

      @include media('>=tablet', '<desktop') {
        padding-bottom: 30px;
        justify-content: center;
      }

      > li {
        &.current-menu-item a {
          border-bottom: 2px solid $yellow;
        }
      }

      li {
        margin-right: 10px;
        padding-right: 10px;
        border-right: 2px solid #d3d3d6;

        @include media('>=tablet', '<desktop') {
          margin-right: 9px;
        }

        @include media('>=widescreen', '<1400px') {
          margin-right: 15px;
          padding-right: 15px;
        }

        @include media('>=1400px') {
          margin-right: 20px;
          padding-right: 20px;
        }

        &:last-of-type {
          margin-right: 0;
          padding-right: 0;
          border-right: none;

          .dropdown-menu {
            @include media('>=tablet','<1500px') {
              left: -56px !important;
            }
          }
        }

        &:hover {
          .dropdown-menu {
            display: block;
          }
        }

        a {
          color: $light-blue;
          font-family: $default-font;
          font-size: 16px;
          font-weight: 400;
          padding-bottom: 0;
          padding-top: 0;

          @include media('>=desktop', '<widescreen') {
            font-size: 11px;
          }

          @include media('>=widescreen', '<1400px') {
            font-size: 14px;
          }

          &:after {
            top: 3px;
            position: relative;
            color: $yellow;
          }
        }

        .dropdown-menu {
          left: 0;
          top: 100%;
          width: 100%;
          margin-top: 0px;
          position: absolute;
          min-width: 163px;

          @include media('>=1360px') {
            min-width: 206px;
          }

          &:last-of-type {
            left: 0;
            right: 0;            
          }

          li {
            float: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            width: 100%;
            display: block;
            text-align: left;
            padding: 5px 10px;
            margin: 0;
            border-right: none;

            a {
              //color: $cerulean;
              padding: 0;
            }

            &:hover, &:focus, &.current-menu-item {

              > a {
                border: none;
                color: $yellow;
              }
            }

            &:last-child {
              border-bottom: none;
            }

            @include media('>=tablet', '<desktop') {
              padding: 10px 9px;
            }

            ul {
              left: 100%;
              top: -1px;
              bottom: auto;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}