.testimonial-part {	
	box-shadow: 0px 12px 21px 0px rgba(0, 0, 0, 0.2);
	background-color: $white;
	position: relative;
	padding: 61px 0 50px;

	@include media('<desktop') {
		box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.2);
	}

	.testimonial-slider {
		margin-top: 30px;

		&__slide {
			padding: 0 130px;
			color: $text;

			.siderbar-item & {
				padding: 0 15px;
			}

			a {
				display: none;
			}

			@include media('<tablet') {
				padding: 0 44px;
			}

			@include media('>=desktop','<widescreen') {
			    padding: 0 89px;
			}

			@include media('>=widescreen','<1344px') {
				padding: 0 95px;

				.siderbar-item & {
					padding: 0 29px;
				}
			}
		}

		cite {			
			color: #666666;
			font-family: $font-work;
			font-size: 18px;
			font-weight: 700;
			letter-spacing: 3px;
			line-height: 24px;
			text-transform: uppercase;
			margin-top: 30px;
			display: block;
		}
	}

	.btn {
		margin-top: 31px;
	}

	.slick-dots {
		display: flex !important;
		justify-content: center;
		margin-top: 30px;
		
		li {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			font-size: 0;
			border: 1px solid $yellow;
			background-color: transparent;
			margin: 0 5px;

			&.slick-active {
				background-color: $yellow;
			}

			button {
				border: none;				
			    padding: 0;
			    line-height: 0;
			    -webkit-appearance: none;
			}

			&:hover {
				cursor: pointer;
			}
		}
	}
}