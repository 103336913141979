.faq-catname {
  display: none;
}

.qa-faqs {
  margin-bottom: 30px;

  .home & {
    @include media('<tablet') {
      margin-bottom: 0;
    }
  }

  /*.page-template-default & {

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 50px;
    }

  }*/

  #qaplus_searchform {
    display: none;
  }

  .qa-faq {
    margin-bottom: 20px;
    border-bottom: 2px solid $light-grey;

    > h3 {
      padding: 0;
      margin-bottom: 0;
    }

    .qa-faq-anchor {
      color: $text;
      text-decoration: none;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      font-family: $default-font;
      display: block;
      padding: 16px 58px 16px 0px;
      background: url("../../images/arrow-faq-down.svg") no-repeat scroll right 25px center;
      background-size: 24px 16px;

      @include media('<tablet') {
        padding: 16px 57px 16px 0px;
      }

      @include media('>=desktop', '<widescreen') {
        padding: 16px 66px 16px 0px;
      }

      &.active {
        background: url("../../images/arrow-faq-up.svg") no-repeat scroll right 25px center;
        background-size: 24px 16px;
      }

    }

    .qa-faq-answer {
      padding: 20px 0px 30px;
      background: white;

      @include media('<tablet') {
        padding-left: 0;
        padding-right: 0;
      }

      p:last-of-type {
        margin-bottom: 0;
      }

      p {
        padding-left: 0;
        margin-bottom: 15px;
        color: $text;
        font-family: $default-font;
        font-size: 18px;
        font-weight: 400;
        line-height: 33px;
      }

      a {
        color: $yellow;
      }

      ul {
        margin-bottom: 25px;
        padding-left: 25px;
        padding-top: 25px;

        &.col-md-6 {
          @include media('<tablet') {
            &:first-of-type {
              margin-bottom: 0px !important;
            }
          }
        }

        li {
          position: relative;
          padding-left: 20px;
          font-family: $default-font;
          font-size: 18px;
          font-weight: 400;
          line-height: 33px;
          margin-bottom: 13px;
          color: $text;

          ul {
            margin-top: 17px;
            margin-bottom: 20px;
          }

          &:after {
            position: absolute;
            content: '';
            left: 0px;
            top: 14px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5.5px 0 5.5px 6px;
            border-color: transparent transparent transparent #fb9127;
          }

        }

      }

      ol {
        margin-bottom: 25px;
        padding-top: 25px;
        clear: both;

        @include media('>=tablet') {
          padding-left: 22px;
          margin-bottom: 11px;
        }

        > li {
          position: relative;
          padding: 0px 0 0px 43px;
          margin-bottom: 21px;
          counter-increment: li;
          line-height: 30px;
          color: $text;

          &:before {
            content: counter(li);
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0px;
            text-align: center;
            background-color: $yellow;
            border-radius: 0;
            height: 28px;
            width: 28px;
            line-height: 25px;
            color: $white;
            font-family: $font-work;
            font-size: 20px;
            font-weight: 700;

            @include media('<tablet') {
              left: 0;
            }

          }

        }

      }

    }

    .qa-faq-title.open + .qa-faq-answer {
      display: block !important;
    }

  }

}