#mobile-nav {
    display: inline-block;
    position: unset;

    .nav-opener {
        width: 80px;
        height: 80px;
        background: $yellow;
        padding: 0 17px; 
        color: $white;
        border: none;
        border-radius: 0;
        position: absolute;
        top: 0;
        border-radius: 0px;
        right: 0px;

        span {
            height: 4px;
            background-color: $white;
            display: block;
            margin-bottom: 7px;
            transition: all 0.3s;

            &:last-child {
              margin-bottom: 0;
            }
        }

        &.open {
            span {
                &:nth-child(1) {      
                    transform: rotate(45deg);
                    position: relative;
                    margin-bottom: 1px;
                    top: 2px;
                }

                &:nth-child(2) {
                    display:none;
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                    position: relative;
                    top: -3px;
                }
            }
        }
    }

    .navbar-collapse {
        position: absolute;
        z-index: 2;
        width: 100%;
        padding: 0px;
        top: 80px;
        left: 0px;
        background: $light-grey;

        .navbar-nav {
            padding: 15px 0;

            li {
                font-size: 16px;
                font-weight: 400;
                font-family: $default-font;
                line-height: 36px;
                letter-spacing: 0.45px;
                padding: 5px 15px;
                text-align: center;

                a {
                    color: $light-blue;
                    display: block;
                    padding: 0;

                    &:hover {
                        color: $yellow;
                    }

                    &:after {
                        color: $yellow;
                    }
                }

                .dropdown-menu {
                    width: 90%;
                    position: unset;
                    //background-color: $cerulean;
                    margin: 0;
                    padding: 0;

                    li {
                        a {
                            color: $light-blue;
                            display: block;
                        }

                        &:hover,
                        &:focus {
                            background: $blue;

                            a {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }
}