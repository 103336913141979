h1,
.section-title {
  color: $blue;
  font-family: $font-work;
  font-size: 50px;
  font-weight: 600;
  line-height: 58px;
  //text-transform: uppercase;
  margin-bottom: 40px;

  @include media('>=desktop', '<widescreen') {
    font-size: 48px;
    line-height: 56px;
  }

  @include media('<tablet') {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 27px;
  }
}

h2,
.section-subtitle {
  color: $blue;
  font-family: $font-work;
  font-size: 39px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 30px;
  text-transform: none;

  @include media('<tablet') {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 25px;
  }

  @include media('>=desktop', '<widescreen') {
    font-size: 37px;
    line-height: 45px;
  }
}

.section-subtitle {
  margin-top: 10px;
}

/*h3 {
  color: $blue;
  font-family: Merriweather;
  font-size: 26px;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 10px;
}

h4 {  
  color: #7f8c8d;
  font-family: $default-font;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.small-section-title {  
  color: #999999;
  font-family: $default-font;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 5px;
  line-height: 28px;
  text-transform: uppercase;

  &.text-right {  
    padding-right: 12px;
    border-right: 4px solid #e1e1e1;
  }

  &.text-left {  
    padding-left: 12px;
    border-left: 4px solid #e1e1e1;
  }
}*/

.entry-content {

  h3 {
    font-family: $font-work;
    font-size: 30px;
    font-weight: 500;
    color: $blue;
    line-height: 38px;
    margin-bottom: 20px;
  }

  h4 {
    font-family: $font-work;
    font-size: 30px;
    font-weight: 700;
    color: $light-blue;
    line-height: 38px;
    margin-bottom: 20px;
    letter-spacing: 5px;
  }


  a {
    color: $yellow;
    text-decoration: underline;
    word-break: break-word;

    &:hover {
      text-decoration: underline;
      color: $blue;
    }

  }

  p {
    margin-bottom: 30px;
  }

  p,
  em {
    color: $text;
    font-family: $default-font;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  em {
    font-style: italic;
  }

  ul:not(.contact-opening-hours):not(.social-icon-list):not(.toc-table-list) {
    margin-bottom: 25px;
    padding-left: 25px;

    &.col-md-6 {
      @include media('<tablet') {
        margin-bottom: 0;

        & + ul {
          margin-bottom: 25px;        
        }
      }
    }

    li {
      position: relative;
      padding-left: 20px;
      font-family: $default-font;
      font-size: 18px;
      font-weight: 400;
      line-height: 33px;
      margin-bottom: 13px;
      color: $text;

      ul {
        margin-top: 17px;
        margin-bottom: 20px;
      }

      &:after {
        position: absolute;
        content: '';
        left: 0px;
        top: 14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5.5px 0 5.5px 6px;
        border-color: transparent transparent transparent #fb9127;
      }

    }

  }

  /*
  ul.col-md-6 {
    @include media('<tablet') {
      &:first-of-type {        
        margin-bottom: 10px !important;
      }
    }

    li {

      &:last-of-type {
        margin-bottom: 0;

        @include media('<tablet') {
          margin-bottom: 0 !important;
        }
      }

    }

  }*/

  ol {
    margin-bottom: 25px;
    //clear: both;

    @include media('>=tablet') {
      padding-left: 22px;
      margin-bottom: 11px;
    }

    > li {
      position: relative;
      padding: 0px 0 0px 43px;
      margin-bottom: 21px;
      counter-increment: li;
      line-height: 30px;
      color: $text;

      &:before {
        content: counter(li);
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0px;
        text-align: center;
        background-color: $yellow;
        border-radius: 0;
        height: 28px;
        width: 28px;
        line-height: 25px;
        color: $white;
        font-family: $font-work;
        font-size: 20px;
        font-weight: 700;

        @include media('<tablet') {
          left: 0;
        }

      }

    }

  }

  blockquote,
  q {
    quotes: none;
    margin-bottom: 35px;

    p {
      padding-left: 30px;
      font-size: 20px;
      color: $light-blue;
      line-height: 30px;
    }
  }

  blockquote {
    border-left: 3px solid $yellow;
  }

  .alignleft {
    display: inline-block;
    float: left;
    margin-bottom: 12px;
    margin-right: 17px;
    position: relative;
    top: 10px;
    height: auto;
    max-width: 100%;

    @include media('<tablet') {
      max-width: 340px;
      margin: 5px auto 15px;
      width: 100%;
    }

  }

  ol + h2,
  ul + h2,
  p + h2 {
    margin-top: 42px;
  }

  .alignright {
    display: inline-block;
    float: right;
    margin-bottom: 15px;
    margin-left: 17px;
    position: relative;
    top: 4px;
    height: auto;
    max-width: 100%;
    text-align: center;

    @include media('<tablet') {
      max-width: 340px;
      margin: 5px auto 15px;
      width: 100%;
    }

  }

  .aligncenter {
    display: block;
    float: none;
    margin: 15px auto;
    position: relative;
    height: auto;
    max-width: 100%;
    text-align: center;

    @include media('<tablet') {
      max-width: 340px;
      margin: 5px auto 15px;
      width: 100%;
    }

  }

  .img-responsive.wp-post-image {
    max-width: 100%;
  }

  .wp-caption {

    @include media('<tablet') {
      margin: 0 auto 25px;
      display: block;
      float: none;
    }

    @include media('<phone') {
      max-width: 100% !important;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 19px;
      text-align: center;
    }

  }

  img[class*="wp-image"] {

    @include media('<tablet') {
      max-width: 260px;
      margin: 20px auto 25px;
      height: auto;
      float: none;
      display: block;
    }

  }

  > table {
    border: 1px solid $black;
    width: 90%;
    margin: 0 auto;
    text-align: left;
    background: $white;

    @include media('>=tablet', '<desktop') {
      width: 100%;
    }

    th,
    td {
      border: none;
      padding-left: 20px !important;
      padding-right: 20px !important;

      @include media('>=tablet', '<desktop') {
        padding: 10px !important;
      }

      @include media('<tablet') {
        padding: 10px 20px !important;
      }

      @include media('<=phone') {
        padding: 10px !important;
      }
    }

    thead {
      background: $black;

      tr {
        background: $black;
      }

      th {
        background: $black;
        color: $white;
        text-transform: uppercase;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }

  > img {
    max-width: 100%;
    margin-bottom: 25px;
    border-radius: 3px;
  }

  .btn {
    margin-right: 28px;
    margin-bottom: 24px;
  }

  .image-holder {
    max-width: 275px;
    float: left;
    margin-bottom: 10px;
    margin-right: 30px;
    padding-top: 10px;

    .single-post & {
      max-width: 275px;
      width: 275px;

      @include media('<tablet') {
        //width: 100%;
        max-width: 100%;
      }

      @include media('>=desktop', '<widescreen') {
        //width: 100%;
        max-width: 100%;
      }

      img {
        width: 100%;
      }
    }

    @include media('>=tablet', '<widescreen') {
      padding-top: 13px;
    }

    img {
      box-shadow: 4px 4px 7px 1px rgba(0, 0, 0, 0.07);
    }

    @include media('<tablet') {
      margin: 0 auto 30px;
      float: none;
    }

  }

}