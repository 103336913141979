.form {
	&-wrap {

		label, input {
			display: block;
			font-size: 18px;
			line-height: 1;
		}

		label {			
			color: $white;
			font-family: $default-font;
			font-size: 18px;
			font-weight: 700;
			line-height: 30px;
			margin-bottom: 5px;
		}

		input, textarea {
			box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
			height: 45px;
			margin-bottom: 25px;
			border: 1px solid #979797;
			background-color: $white;
			
			@include placeholder {
				color: #535353;
			}
		}

		textarea {
			height: 100px;
			padding-top: 15px;
			margin-bottom: 0;
		}

		.btn {
			width: 100%;
			margin-top: 12px;

			@include media('<tablet') {
	         padding-left: 30px;
	         padding-right: 30px;
	        }
		}
	}

	&-subtitle {		
		color: $yellow;
		font-family: $font-work;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 3px;
		line-height: 38px;
		text-transform: uppercase;

		br {
			display: none;
		}

		@include media('<tablet') {
         font-size: 16px;
         letter-spacing: 1px;
        }
	}

	&-title {		
		color: $white;
		font-family: $font-work;
		font-size: 40px;
		font-weight: 700;
		line-height: 48px;
		margin-bottom: 30px;

		@include media('<tablet') {
         font-size: 30px;
         line-height: 38px;
        }
	}
}