// ---------------------------------------------------------
// Helpers
// ---------------------------------------------------------

// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Clearfix */

.clearfix {
  display: block;
  zoom: 1;

  &:after {
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

%bg-stretch {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center top !important;
}

%clearfix {
  display: block;
  zoom: 1;

  &:after {
    content: " ";
    display: block;
    font-size: 0;
    height: 0;
    clear: both;
    visibility: hidden;
  }
}

// transition
%transition {
  transition: all 0.2s ease;
}


/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

/* ---------- Removing The Dotted Outline ---------- */

input::-moz-focus-inner {
  border: 0;
}

button:hover, button:active, button:focus,
a:hover, a:active, a:focus,
input:hover, input:active, input:focus,
select:hover, select:active, select:focus,
textarea:hover, textarea:active, textarea:focus {
  outline: none;
  -moz-outline-style: none;
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

input, textarea {
  border: none;
}

.txt {
  font-size: 0 !important;
  line-height: 0;
  outline: medium none;
  overflow: hidden;
  text-indent: -9999px !important;
}

.clear {
  clear: both;
  visibility: hidden;
  height: 0;
  width: 0;
  line-height: 0;
}

.text-center {
  text-align: center;

  &-sm {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  &-md {
    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }

}

.uppercase {
  text-transform: uppercase;
}

/* CWS helpers */

* {
  max-height: 1000000px;
}

body {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
}

/* set default styles in all browsers */

input[type="search"] {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  outline: none;
  box-shadow: none;
}

textarea {
  overflow: auto;
  resize: none;
}

img {
  vertical-align: top;
}

a {
  text-decoration: underline;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out, opacity 0.3s ease-in-out;

  &:focus,
  &:hover {
    outline: none;
  }
}

@media only screen and (min-width: 1024px) {
  a[href^=tel], a[href^=skype], a[href^=sms] {
    cursor: default;
    pointer-events: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {

  a {
    text-decoration: none;
  }
}

//Wordpress Styles
$vertical-rhythm: 1.2em !default;
$btn-background: #666 !default;
$btn-color: #fff !default;

/// #main { @extend %clearfix; }
/// #content { float: left; width: 70%; }
/// #sidebar { float: right; width: 25%; }
body {
  margin: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  height: auto;
}

iframe {
  max-width: 100%;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'],
input[type='password'],
input[type='url'],
input[type='date'],
textarea {
  .comment-form &,
  .search-form &,
  .post-password-form & {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border: 1px solid #999;
    padding: .2em .7em;

    // &:hover {

    // }

    &:focus {
      border-color: #333;
    }

    @include placeholder {
      color: #000;
    }
  }

}

.widget {
  margin-bottom: 1.2em;
  @extend %clearfix;

  ul {
    padding-left: 2em;
  }

  select {
    min-width: 150px;
  }
}

.comment-form {

  label {
    display: block;
    padding-bottom: 4px;
  }

  textarea {
    overflow: auto;
    margin: 0;
    height: 120px;
    min-height: 120px;
  }

  textarea,
  input[type='text'] {
    margin: 0 4px 0 0;
    width: 300px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  input[type='submit'] {
    display: block;
    float: none;
  }
}

.post-password-form {
  label {
    display: block;
  }

  input[type='password'] {
    margin: 0 4px 0 0;
    width: 300px;
  }
}

.search-form {
  @extend %clearfix;

  input {
    float: left;
    height: 2em;

    &[type='search'] {
      margin: 0 4px 0 0;
      width: 150px;
    }
  }
}

.post-password-form {
  p {
    @extend %clearfix;
  }

  input {
    float: left;
    height: 2em;

    &[type='password'] {
      width: 150px;
    }
  }
}

.commentlist {
  margin: 0 0 1.2em;

  .edit-link {
    margin: 0;
  }

  .avatar-holder {
    float: left;
    margin: 0 1.2em 4px 0;
  }
}

.commentlist-item {
  .commentlist-item {
    padding: 0;
    @media screen and (min-width: 768px) {
      padding: 0 0 0 2em;

    }
  }
}

.comment,
.commentlist-holder {
  overflow: hidden;
}

.commentlist-item .commentlist-item,
.commentlist-item + .commentlist-item {
  padding-top: 1.2em;
}

.widget_calendar {
  #next {
    text-align: right;
  }
}

.gallery-item {
  dt {
    float: none;
    padding: 0;
  }
}

.navigation,
.navigation-comments {
  position: relative;
  vertical-align: middle;
  margin-bottom: $vertical-rhythm;

  .screen-reader-text {
    position: absolute;
    left: -99999px;
  }

  .page-numbers {
    display: inline-block;
    vertical-align: middle;
    padding: 0 .4em;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .next,
  .prev {
    display: inline-block;
    vertical-align: middle;
    max-width: 50%;
    background: $btn-background;
    color: $btn-color;
    padding: .4em;
    text-decoration: none;
    @media screen and (max-width: 767px) {
      padding: .2em;
    }
  }
}

.navigation-single {
  @extend %clearfix;
  margin-bottom: $vertical-rhythm;

  .next,
  .prev {
    float: left;
    vertical-align: middle;
    max-width: 50%;
    background: $btn-background;
    color: $btn-color;
    text-decoration: none;

    a {
      color: inherit;
      text-decoration: none;
      padding: .4em;
      display: block;
      @media screen and (max-width: 767px) {
        padding: .2em;
      }
    }
  }

  .next {
    float: right;
  }
}

.wp-caption {
  max-width: 100%;
  clear: both;
  //border: 1px solid #ccc;
  padding: .4em;
  margin-bottom: 1.2em;

  img {
    margin: 0;
    vertical-align: top;
  }

  p {
    margin: .4em 0 0;
  }

}

div.aligncenter {
  margin: 0 auto 1.2em;
}

img.aligncenter {
  display: block;
  margin: 0 auto;
}

img.alignleft {
  float: left;

  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
    display: block;
    float: none;
  }

}

img.alignright {
  float: right;
  margin: 10px 0 6px 1.6em;
  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
    display: block;
    float: none;
  }
}

img.alignnone {
  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
    display: block;
  }
}

.mejs-container {
  margin-bottom: 1.2em;
}

.wp-caption-text,
.gallery,
.alignnone,
.gallery-caption,
.sticky,
.bypostauthor {
  height: auto;
}

th,
td {
  border: 1px solid #999;
  padding: 0.5em;
}

/* CWS additional helpers */

.meta { // class used to hide the meta
  display: none;
}

.rel {
  position: relative;
}

/**Backgrounds**/

.pull {
  &-right {
    @media screen and (min-width: 992px) {
      &-md {
        float: right !important;
      }
    }
  }
}

.no-p {
  p {
    display: none;
  }

  + p {
    display: none;
  }
}

.img-full {
  width: 100%;
}

.no-pad {
  &-all {
    padding: 0 !important;
  }

  @media screen and (min-width: 768px) {
    padding: 0 !important;
    &-left {
      padding-left: 0 !important;
    }
    &-right {
      padding-right: 0 !important;
    }
  }

  @media screen and (min-width: 1200px) {
    &-left-lg {
      padding-left: 0 !important;
    }
  }

  @media screen and (max-width: 991px) {
    &-sm {
      padding: 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    &-mobile {
      padding: 0 !important;
    }
  }
}


.pt {
  &-25 {
    padding-top: 25px;
  }
}

.pr {
  &-30 {
    @media screen and (min-width: 992px) {
      padding-right: 30px;
    }

    &-lg {
      @media screen and (min-width: 1200px) {
        padding-right: 30px;
      }
    }
  }

  &-40 {
    @media screen and (min-width: 992px) {
      padding-right: 40px;
    }

    &-lg {
      @media screen and (min-width: 1200px) {
        padding-right: 40px;
      }
    }
  }

  &-45 {
    @media screen and (min-width: 992px) {
      padding-right: 45px;
    }
  }

  &-80 {
    &-lg {
      @media screen and (min-width: 1200px) {
        padding-right: 80px;
      }
    }
  }
}

.pb {
  &-25 {
    padding-bottom: 25px;
  }
}

.pl {
  &-30 {
    @media screen and (min-width: 768px) {
      padding-left: 30px;
    }
  }
}


.mt {
  &-25 {
    margin-top: 25px;
  }
}

.mb {
  &-25 {
    margin-bottom: 25px;
  }
}


.bg {
  &-gray {
    background-color: #f7f7f7;
  }
}

.bq {
  &-line-gray {
    border-left: 5px solid #eee;
  }
}


.center-col {
  float: none;
  margin: 0 auto;
}

.pos-rel {
  position: relative;
}

.ab-vert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.modal-body {
  padding-top: 40px;

  .cw_video_description {
    display: none;
  }

  .cw_video_single,
  iframe {
    width: 100%;
    height: 369px;
  }
}

.modal-content {
  .close {
    position: relative;
    right: 10px;
    z-index: 9999;
    opacity: 1;

    span {
      color: white;
    }

  }
}


// two column list
%two-column-list {
  -moz-column-count: 2;
  -moz-column-gap: 0px;
  -webkit-column-count: 2;
  -webkit-column-gap: 0px;
  column-count: 2;
  column-gap: 0px;
  @media(max-width: 1199px) {
    -moz-column-gap: 0px;
    -webkit-column-count: 1;
    -webkit-column-gap: 0px;
    column-count: 1;
    column-gap: 0px;
  }
}

// remove two column list
%remove-two-column-list {
  -moz-column-count: 1;
  -moz-column-gap: 0px;
  -webkit-column-count: 1;
  -webkit-column-gap: 0px;
  column-count: 1;
  column-gap: 0px;
}

//
// Responsive: Utility classes
// --------------------------------------------------


// IE10 in Windows (Phone) 8
//
// Support for responsive views via media queries is kind of borked in IE10, for
// Surface/desktop in split view and for Windows Phone 8. This particular fix
// must be accompanied by a snippet of JavaScript to sniff the user agent and
// apply some conditional CSS to *only* the Surface/desktop Windows 8. Look at
// our Getting Started page for more information on this bug.
//
// For more information, see the following:
//
// Issue: https://github.com/twbs/bootstrap/issues/10497
// Docs: http://getbootstrap.com/getting-started/#support-ie10-width
// Source: http://timkadlec.com/2013/01/windows-phone-8-and-device-width/
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/

@at-root {
  @-ms-viewport {
    width: device-width;
  }
}
