.page-template-contact {

  .entry-content p {
    margin-bottom: 15px;
  }

  .breadcrumb.col {
    border-top: 1px solid aliceblue;
    border-bottom: 1px solid aliceblue;
    padding-bottom: 15px;
  }

  .phone-social {
    align-items: center;
  }

  #contact-one {
    padding-bottom: 50px;

    .form-wrap {
      background-color: $blue;
      padding: 60px 100px;
      margin: 29px 0 35px;

      @include media('<tablet') {
        padding-left: 15px;
        padding-right: 15px;

        .form-subtitle {
          font-size: 15px;
        }

      }

    }

    .sidebar-title {
      margin-bottom: 10px;
    }

  }

  .call-to-action {

    .phone {

      @include media('>=tablet') {
        margin-bottom: 0;
      }

      @include media('>=widescreen', '<1400px') {
        font-size: 61px;
      }

    }

  }

}

#contact-two {

  .map-details-wrap {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
    max-width: 667px;
    padding: 23px 30px 50px;

    .law-firm-footer-title {
      color: $blue;
    }

    #map-canvas {

      @include media('>=desktop', '<1400px') {
        max-width: 100%;
      }

    }

    .wpseo-address-wrapper,
    .phone-address a,
    .phone-address {
      color: $text !important;
    }

    ul {
      margin-top: 30px;

      li:first-of-type {

        @include media('<tablet') {
          margin-right: 15px !important;

          a {
            max-width: 130px !important;
          }

        }

      }

    }

  }

  .expect-section {
    background-color: $light-grey;
    padding: 68px 90px 65px;

    @include media('>=desktop', '<widescreen') {
      padding-left: 30px;
      padding-right: 30px;
    }

    @include media('<tablet') {
      padding: 30px 7px;
      margin-top: 40px;
    }

    p {
      margin-bottom: 45px;

      @include media('>=576px', '<tablet') {
        padding-left: 15px;
        padding-right: 15px;
      }

    }

    hr {
      width: 377px;
      height: 2px;
      background-color: #ccc;
      border: none;

      @include media('<tablet') {
        width: 100%;
      }

      &:last-of-type {
        margin-bottom: 30px;
      }

    }

    img {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    a {
      display: block;
      font-weight: 700;
      color: $light-blue;
      margin-bottom: 30px;
      padding-right: 0;
    }

  }

}