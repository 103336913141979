.page-template-page-fatal-pedestrian-accidents,
.page-template-page-driving-near-trucks,
.page-template-infographic-accidents-due-to-snow {

  .hero-default {
    min-height: 50px;

    @include media('<tablet') {
      display: none !important;
    }

    > .container {
      display: none;
    }

  }

}

.page-template-page-legal-services {

  .sidebar-related {
    display: none;
  }

}